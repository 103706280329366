import _ from 'lodash';

export const FLAG_DOCS = {
    CLAIM_PARK_TUTORIAL_FINISHED: {
        name: 'claimParkTutorialFinished',
        desc: 'Set when student finished park tutorial',
    },
    CLICKED_GET_STARTED: {
        name: 'clickedGetStarted',
        desc: 'Set when teacher started fluency mode intro',
    },
    CLICKED_SOLO_MODE_1: {
        name: 'clickedSoloMode1',
        desc: 'Set on first solo mode try by the teacher',
    },
    CLICKED_SOLO_MODE_2: {
        name: 'clickedSoloMode2',
        desc: 'Set on second solo mode try by the teacher',
    },
    CLICKED_SOLO_MODE_3: {
        name: 'clickedSoloMode3',
        desc: 'Set on third solo mode try by the teacher. Removes "Popular!" badge',
        //todo: flag may take counter instead of boolean; use one flag not three
    },
    CLOSED_STUDENT_LINKING: {
        name: 'closedStudentLinking',
        desc: 'Marks if student has a parent account linked for a certain class. Stored as `closedStudentLinking:${this.selectedClassCode}`',
    },
    COMMON_MISTAKES_LAST_CLOSED: {
        name: 'commonMistakesLastClosed',
        desc: 'Controls TBL mistakes modal',
    },
    COMMON_MISTAKES_LAST_OPENED: {
        name: 'commonMistakesLastOpened',
        desc: 'Controls TBL mistakes modal',
    },
    DEBUG_USER: {
        name: 'debugUser',
        desc: 'Saved in game meta, makes some debug processing',
    },
    DEMO_STUDENT: {
        name: 'demoStudent',
        desc: 'Marks fake student account created for the teacher demo',
    },
    FIRST_LOGIN_CLEVER_SYNC: {
        name: 'firstLoginCleverSync',
        desc: 'Helps to sync existing 99math account with Clever instead of creating a new one',
    },
    FREE_ENERGY_REFILL: {
        name: 'freeEnergyRefill',
        desc: 'First energy refill is free. The flag marks that the feature has been used.',
    },
    FTUE_SOUND: {
        name: 'ftueSound',
        desc: 'Controls sound to be on during student`s FTUE flow',
    },
    HAS_CLICKED_SKILLS_LIST_TOPIC: {
        name: 'hasClickedSkillsListTopic',
        desc: '??? Used in teacher`s SkillSelector to control topic modal. Purpose is unclear however',
    },
    HAS_PLAYED_DEMO_GAME: {
        name: 'hasPlayedDemoGame',
        desc: 'Set after teacher plays a demo game',
    },
    HAS_SENT_SIGN_UP_EVENT: {
        name: 'hasSentSignUpEvent',
        desc: "Set after sending MixPanel event for the teacher's sign up",
    },
    HAS_SET_MBUCKS_AND_AI_CREDITS_MP: {
        name: 'hasSetmBucksAndAICreditsMP',
        desc: 'Limits the corresponding budgets to be tracked in MixPanel only once',
    },
    HIDE_JUNE_BUNDLE_SALES: {
        name: 'hideJuneBundleSales',
        desc: 'Turns off bundle offer for June 2024',
    },
    IS_PROGRAM_PAGE_VIEWED: {
        name: 'isProgramPageViewed',
        desc: 'Set after user visited a Solo Track page',
    },
    PARENT_SIGNUP_FACEBOOK: {
        name: 'parentSignupFacebook',
        desc: 'Marks Facebook signup for the parent account. Works in pair with the same-name local storage prop',
    },
    PARK_TUTORIAL_FINISHED: {
        name: 'parkTutorialFinished',
        desc: 'Marks part tutorial as viewed',
    },
    PLAYED_AT_HOME: {
        name: 'playedAtHome',
        desc: 'Marks that user opened the app at home time',
    },
    RECENTLY_PLAYED_LOADED: {
        name: 'recentlyPlayedLoaded',
        desc: 'Marks that Recently Played games were shown to the teacher, to send MixPanel event for this only once',
    },
    SEEN_ARENA_ENERGY_INSTRUCTION: {
        name: 'seenArenaEnergyInstruction',
        desc: 'Controls Math Runner energy instruction popup',
    },
    SEEN_CITY_HUB_FIRST_TIME: {
        name: 'seenCityHubFirstTime',
        desc: 'Checks the first City visit not to show random citizens at the first time',
    },
    SEEN_SOLO_TRACK_DATA: {
        name: 'seenSoloTrackData',
        desc: 'Marks that teacher has seen Solo Track data for a certain class. Stored as `seenSoloTrackData.` + classCode',
    },
    SEE_FRAMES: {
        name: 'seeFrames',
        desc: 'Controls if the user should see the avatar frames',
    },
    SEE_RECENTLY_PLAYED_TOOLTIP: {
        name: 'seeRecentlyPlayedTooltip',
        desc: '??? Legacy. Controls the tooltip in one place, does nothing in another',
    },
    SENT_REMINDER: {
        name: 'sentReminder',
        desc: 'Reminder for the teacher to play TBL with students after a demo',
    },
    SKILLS_LEVEL_LOCK: {
        name: 'skillsLevelLock',
        desc: '??? Legacy. Is used to lock topics in old /topics-page',
    },
    SKILL_LIST_SEARCH_TESTER: {
        name: 'skillListSearchTester',
        desc: 'Used to show/hide skill search for a teacher',
    },
    SKIP_SET_CLASS: {
        name: 'skipSetClass',
        desc: '??? Legacy. Used in orphaned StudentClassSurvey',
    },
    SOLO_TRACK: {
        name: 'soloTrack',
        desc: '??? Usages are commented out',
    },
    SPG_ASSIGNMENTS_VIEWED: {
        name: 'spgAssignmentsViewed',
        desc: 'Controls new assignment popup for the student',
    },
    STARTED_FIRST_GAME: {
        name: 'startedFirstGame',
        desc: 'Marks that player has started their first game. Used to reduce functionality during onboarding.',
    },
    TBL_INTRO_PASSED: {
        name: 'tblIntroPassed',
        desc: 'Marks that teacher has seen the TBL intro not to show it again',
    },
    TEACHER_ASSIGN_PATH: {
        name: 'teacherAssignPath',
        desc: 'Marks that teacher has assigned ST path to a class',
    },
    USER_SAW_SOLO_MODE_INTRO: {
        name: 'userSawSoloModeIntro',
        desc: 'Controls whether to show ST intro to the teacher',
    },
    STUDENT_POST_REL_24H2_INTRO_SEEN_ONCE: {
        name: 'studentPostRel24H2IntroSeenOnce',
        desc: 'Marks if student has seen the new design intro once',
    },
    STUDENT_POST_REL_24H2_INTRO_SEEN_TWICE: {
        name: 'studentPostRel24H2IntroSeenTwice',
        desc: 'Marks if student has seen the new design intro twice',
    },
    FIRST_TBL_TO_HOME_PRACTICE_NAV: {
        name: 'firstTblToHomePracticeNav',
        desc: 'Set after a teacher has navigated from TBL to Home Practice',
    },
    BATTLE_PASS_V_21_SYNCED: {
        name: 'battlePassV21Synced',
        desc: 'Set after syncing battlepass from levelsV2.json to levelsV3.json, it adds enough tasks solved to reach the same level as with old configuration',
    },
    DISMISS_WELCOME_PAGE: {
        name: 'dismissWelcomePage',
        desc: 'Set after a teacher has dismissed the welcome page in new UI',
    },
    DISMISS_GOAL_WHY_AND_HOW: {
        name: 'dismissGoalWhyAndHow',
        desc: 'Set after a teacher has dismissed the Why&How block on the Weekly Goal page',
    },
    DISMISS_COMPETITION_REG_PROMO: {
        name: 'dismissCompetitionRegPromo',
        desc: 'Set when teacher collapses competition info block on registration page',
    },
    PAID_RES_SURVEY_ANSWERED: {
        name: 'paidResSurveyAnswered',
        desc: 'Set after a teacher has answered the paid resources survey',
    },
    PAID_HOME_ACADEMY_EXPERIMENT: {
        name: 'paidHomeAcademyExperiment',
        desc: 'Set after a teacher in group 1 that has not registered for monthly competition logs in or signs up',
    },
    NOT_PAID_HOME_ACADEMY_EXPERIMENT: {
        name: 'notPaidHomeAcademyExperiment',
        desc: 'Set after a teacher not in group 1 or has registered for monthly competition logs in or signs up',
    },
    GETTING_MONTHLY_COMPETITION_REWARD: {
        name: 'gettingMonthlyCompetitionReward',
        desc: 'Set after a student has received a reward for the monthly competition',
    },
};

export const FLAGS = _.mapValues(FLAG_DOCS, 'name');
