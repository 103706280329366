/* global mixpanel */
import customAvatarJSON from '@/core/static-json/avatar/customAvatar.json';

export const HOME_GAME_V10_MP_EVENTS = {
    SET_HOMEGAME_VERSION: 'setHomeGameVersion',
    VIEWED_OWN_CLASS_PAGE: 'viewedOwnClassPage',
    VIEWED_OTHER_CLASS_PAGE: 'viewedOtherClassPage',
    VIEWED_AVATARS_PAGE: 'viewedAvatarsPage',
    VIEWED_CLASSES_LEADERBOARD: 'viewedClassesLeaderboard',
    CLICK_ON_SEE_CLASS_PAGE: 'clickOnSeeClassPage',
    VIEWED_TOPICS_PAGE: 'viewedTopicsPage',
    CHANGED_AVATAR: 'changedAvatar',
    CHANGED_FRAME: 'changedFrame',
    EARNED_NEW_REWARD: 'earnedNewReward',
    ASK_TEACHER_FOR_ASSIGNMENT_BUTTON_CLICKED:
        'askTeacherForAssignmentButtonClicked',
    OPENED_TIMER_CHEST: 'openedTimerChest',
    SUBMITTED_PARENT_EMAIL: 'submittedParentEmail',
    SUBMITTED_PARENT_EMAIL_SETTINGS: 'submittedParentEmailSettings',
    SKIPPED_PARENT_EMAIL: 'skippedParentEmail',
    AT_GAME_FINISHED: 'atGameFinished',

    // island events
    RUN_ISLAND_CONSTRUCTION: 'runIslandConstruction',
    VIEW_ONGOING_CONSTRUCTION: 'viewOnGoingConstruction',
    CLICK_SPEEDUP_CONSTRUCTION: 'clickSpeedUpConstruction',
    ISLAND_NAVIGATION_OPENED: 'islandNavigationOpened',
    ISLAND_NAVIGATION_CLOSED: 'islandNavigationClosed',
    NEW_ISLAND_SELECTED: 'newIslandSelected',
    ISLAND_COMPLETED: 'islandCompleted',
    ISLAND_COMPLETION_REWARD_CLAIMED: 'islandCompletionRewardClaimed',
    ISLAND_COMPLETION_REWARD_CLOSED: 'islandCompletionRewardClosed',
    LOCKED_ISLAND_CLICKED: 'lockedIslandClicked',
    USER_IS_OUT_OF_LEGENDARY_BACKGROUNDS: 'userIsOutOfLegendaryBackgrounds',
    STUDENT_SEES_OPEN_SOCIAL_WIDGET: 'studentSeesOpenSocialWidget',
    STUDENT_CLOSES_SOCIAL_WIDGET: 'studentClosesSocialWidget',
    STUDENT_OPENS_SOCIAL_WIDGET: 'studentOpensSocialWidget',
    STUDENT_CHANGES_TAB_SOCIAL_WIDGET: 'studentChangesTabSocialWidget',
    STUDENT_CLICKS_ON_PLAYER_SOCIAL_WIDGET:
        'studentClicksOnPlayerInSocialWidget',
    STUDENT_SENDS_FRIEND_REQUEST_SOCIAL_WIDGET:
        'studentSendsFriendRequestInSocialWidget',
    STUDENT_REJECTS_FRIEND_REQUEST_SOCIAL_WIDGET:
        'studentRejectsFriendRequestInSocialWidget',
    STUDENT_ACCEPTS_FRIEND_REQUEST_SOCIAL_WIDGET:
        'studentAcceptsFriendRequestInSocialWidget',
    STUDENT_FRIENDS_COUNT: 'setStudentFriendsCount',
    AT_REPLAY_SKILL: 'atReplaySkill',
};

export const HOME_GAME_V10_MP_METHODS = {
    runIslandConstruction(phase) {
        // mixpanel.track('Student run island construction', {
        //     'Island Phase': phase,
        // });
    },
    viewOnGoingConstruction(phase) {
        // mixpanel.track('Student view ongoing island construction', {
        //     'Island Phase': phase,
        // });
    },
    clickSpeedUpConstruction({ phase, price }) {
        // mixpanel.track('Student Speed up island construction', {
        //     'Island Phase': phase,
        //     Price: price,
        // });
    },
    setHomeGameVersion() {
        mixpanel.people.set_once({
            'Home game version': '10.1',
        });
    },
    submittedParentEmail(emails) {
        mixpanel.track('Submitted parent email in the modal', {
            'Parent emails': emails.join(', '),
        });
    },
    submittedParentEmailSettings(emails) {
        mixpanel.track('Submitted parent email in the settings', {
            'Parent emails': emails.join(', '),
        });
    },
    skippedParentEmail() {
        mixpanel.track('Skipped parent email entry');
    },
    viewedOwnClassPage() {
        mixpanel.track('Viewed own class page');
    },
    viewedOtherClassPage() {
        mixpanel.track('Viewed other class page');
    },
    viewedAvatarsPage() {
        mixpanel.track('Viewed avatars page');
    },
    viewedClassesLeaderboard() {
        mixpanel.track('Viewed classes leaderboard');
    },
    clickOnSeeClassPage() {
        mixpanel.track('User click on see class button');
    },
    viewedTopicsPage() {
        mixpanel.track('Viewed home game topics list');
    },
    changedAvatar({ type, name, status, mBucks }) {
        // mixpanel.track('Changed avatar', {
        //     'Last selected avatar part': `${type}:${name}`,
        //     'Last selected avatar price': status,
        //     MBucks: mBucks,
        // });
        mixpanel.register({
            'Last selected avatar part': `${type}:${name}`,
            'Last selected avatar price': status,
            MBucks: mBucks,
        });
        mixpanel.people.set({
            'Last selected avatar part': `${type}:${name}`,
            'Last selected avatar price': status,
            MBucks: mBucks,
        });
    },
    earnedNewReward(level) {
        mixpanel.register({
            'Player level': level,
        });
        mixpanel.people.set({
            'Player level': level,
        });
    },
    askTeacherForAssignmentButtonClicked() {
        mixpanel.track('Clicked ask teacher for an assignment button');
    },
    changedFrame(frame) {
        mixpanel.track('Changed frame', {
            'Frame ID': frame,
        });
        mixpanel.register({
            'Current frame': frame,
        });
        mixpanel.people.set({
            'Current frame': frame,
        });
    },
    openedTimerChest() {
        mixpanel.track('Opened timer chest');
    },
    atGameFinished({ problemsSolved, island }) {
        // mixpanel.track('AT game finished', {
        //     'Problems solved': problemsSolved,
        //     'Started from island': island,
        // });
    },
    islandNavigationOpened() {
        mixpanel.track('Island navigation clicked');
    },
    islandNavigationClosed() {
        // mixpanel.track('Island navigation closed without island selection');
    },
    newIslandSelected(islandKey) {
        mixpanel.track('User navigates to another island', {
            'New island': islandKey,
        });
    },
    islandCompleted(islandKey) {
        mixpanel.track('Island completed', {
            'Completed island': islandKey,
        });
    },
    islandCompletionRewardClaimed({ islandKey, reward }) {
        mixpanel.track('Island completion reward clamed', {
            'Completed island': islandKey,
            'Reward selected': reward,
        });
    },
    islandCompletionRewardClosed({ islandKey, cheatSuspect }) {
        // mixpanel.track('Island completion reward close', {
        //     'Completed island': islandKey,
        //     'Anti-cheat auto-close': cheatSuspect,
        // });
    },
    lockedIslandClicked(islandCard) {
        // mixpanel.track('Locked island navigation attempt', {
        //     'Island number': islandCard.number,
        //     'Island name': islandCard.name,
        // });
    },
    userIsOutOfLegendaryBackgrounds() {
        mixpanel.track(
            'No legendary backgrounds are available \
                   as a level completion reward.  \
                   User already has them all.',
        );
    },
    studentSeesOpenSocialWidget({ openedTab }) {
        // mixpanel.track('Student sees open Social Widget', {
        //     'Opened tab': openedTab,
        // });
    },
    studentClosesSocialWidget() {
        // mixpanel.track('Student closes Social Widget');
    },
    studentOpensSocialWidget() {
        // mixpanel.track('Student opens Social Widget');
    },
    studentChangesTabSocialWidget({ tabName }) {
        // mixpanel.track('Student changes tab in Social Widget', {
        //     'Tab name': tabName,
        // });
    },
    studentClicksOnPlayerInSocialWidget() {
        mixpanel.track('Students clicks on a player in Social Widget');
    },
    studentSendsFriendRequestInSocialWidget() {
        mixpanel.track('Student Sends Friend Request from Social Widget');
    },
    studentRejectsFriendRequestInSocialWidget() {
        // mixpanel.track('Student Reject Friend Request');
    },
    studentAcceptsFriendRequestInSocialWidget() {
        mixpanel.track('Students Accepts Friend Request');
    },
    setStudentFriendsCount({ friendsCount }) {
        mixpanel.people.set({ 'Friends count': friendsCount });
    },
    atReplaySkill(skill) {
        mixpanel.track('AT replay skill', {
            'Skill level': skill?.level,
            'Skill topic': skill?.topic,
            'Skill accuracy': skill?.accuracy,
            'Skill fluency': skill?.fluency,
        });
    },
};
