<template>
    <div class="ptem-wrapper">
        <div class="ptem-container">
            <img
                src="/images/quests-widget/close-btn.svg"
                alt="close"
                class="ptem-close-btn"
                @click="closeModal"
            />
            <div class="ptem-header">
                <img src="/images/crown.svg" alt="crown" />
                <span>Your 99math Premium trial has ended</span>
            </div>
            <span class="ptem-subheader">
                Well done!
                <br />
                Let’s upgrade your school to Premium and keep progressing!
            </span>
            <ShareWithAdminBlock />
            <div class="ptem-bottom-block">
                <span class="ptem-text">
                    If you want to learn more, check out our pricing page with
                    plans and all benefits.
                </span>
                <span class="ptem-text ptem-text__gold">
                    We also have valuable data for administrators that they
                    really appreciate!
                </span>
                <button class="ptem-btn" @click="goToPremiumPage">
                    See pricing
                </button>
            </div>
            <img
                src="/images/teacher-premium/trial-modal/people.svg"
                alt="people"
                class="ptem-people-img"
            />
        </div>
    </div>
</template>

<script>
import ShareWithAdminBlock from '@/teacher/flows/TeacherPremium/Modals/components/ShareWithAdminBlock.vue';
import { mapActions } from 'vuex';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    components: {
        ShareWithAdminBlock,
    },
    created() {
        new TrackingService().track(EVENTS.OPEN_PREMIUM_TRIAL_END_MODAL, {
            location: this.$route.path,
        });
    },
    methods: {
        ...mapActions('v2/teacherPremium', ['setShowPremiumTrialEndModal']),
        closeModal() {
            this.setShowPremiumTrialEndModal(null);
        },
        goToPremiumPage() {
            new TrackingService().track(EVENTS.OPEN_PREMIUM_PRICING_PAGE, {
                location: 'Premium trial end modal',
            });

            this.closeModal();

            this.$router.push({ name: 'teacher.premium' });
        },
    },
};
</script>
<style lang="scss" scoped>
.ptem-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
}
.ptem-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 750px;
    height: 600px;
    border-radius: 10px;
    border: 2px solid rgba(255, 208, 91, 0.5);
    background: linear-gradient(180deg, #6f24d0 0%, #360773 34%);

    @media screen and (max-width: $w-max-desktop) {
        width: 706px;
        height: 546px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 90%;
        height: 90%;
    }
}
.ptem-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: translate(-10px, 10px);
    cursor: pointer;

    @media screen and (max-width: $w-max-phone) {
        width: 30px;
        height: 30px;
    }
}
.ptem-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 50px 0;

    @media screen and (max-width: $w-max-phone) {
        gap: 6px;
        margin: 10px 0;
    }

    img {
        width: 34px;
        height: 26px;

        @media screen and (max-width: $w-max-phone) {
            width: 28px;
            height: 20px;
        }
    }

    span {
        color: var(--Identity-Accent-Live-games, #ffc75a);
        text-align: center;
        font-family: $inter-font-family;
        font-size: 28px;
        font-weight: 700;

        @media screen and (max-width: $w-max-desktop) {
            font-size: 24px;
        }

        @media screen and (max-width: $w-max-phone) {
            max-width: 200px;
            font-size: 18px;
        }
    }
}
.ptem-subheader {
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 22px;
    font-weight: 700;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 16px;
    }
}
.ptem-bottom-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    @media screen and (max-width: $w-max-phone) {
        margin-top: 30px;
    }
}
.ptem-text {
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 19px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 17px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 16px;
    }

    &__gold {
        color: var(--Identity-Accent-Live-games, #ffc75a);

        @media screen and (max-width: $w-max-phone) {
            margin-top: 10px;
        }
    }
}
.ptem-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 50px;
    margin-top: 20px;
    border-radius: 8px;
    border: 1.5px solid rgba(240, 138, 79, 0.7);
    background: var(--Darkest-purple, #360773);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    color: var(--UI-White, #fff);
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;

    @media screen and (max-width: $w-max-desktop) {
        width: 216px;
        height: 46px;
        margin-top: 12px;
        font-size: 18px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 200px;
        height: 40px;
        font-size: 16px;
    }
}
.ptem-people-img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(-36px);

    @media screen and (max-width: $w-max-phone) {
        right: 50%;
        transform: translateX(50%);
    }
}
</style>
