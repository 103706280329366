// head
import Head1 from '/public/images/custom-avatar/head/head_1.svg?component';
// body
import Body1 from '/public/images/custom-avatar/body/body_1.svg?component';
import Body2 from '/public/images/custom-avatar/body/body_2.svg?component';
import Body3 from '/public/images/custom-avatar/body/body_3.svg?component';
import Body4 from '/public/images/custom-avatar/body/body_4.svg?component';
import Body5 from '/public/images/custom-avatar/body/body_5.svg?component';
import Body6 from '/public/images/custom-avatar/body/body_6.svg?component';
import Body7 from '/public/images/custom-avatar/body/body_7.svg?component';
import Body8 from '/public/images/custom-avatar/body/body_8.svg?component';
import Body9 from '/public/images/custom-avatar/body/body_9.svg?component';
import Body10 from '/public/images/custom-avatar/body/body_10.svg?component';
import Body11 from '/public/images/custom-avatar/body/body_11.svg?component';
import Body12 from '/public/images/custom-avatar/body/body_12.svg?component';
import Body13 from '/public/images/custom-avatar/body/body_13.svg?component';
import Body14 from '/public/images/custom-avatar/body/body_14.svg?component';
import Body15 from '/public/images/custom-avatar/body/body_15.svg?component';
import Body16 from '/public/images/custom-avatar/body/body_16.svg?component';
import Body17 from '/public/images/custom-avatar/body/body_17.svg?component';
import Body18 from '/public/images/custom-avatar/body/body_18.svg?component';
import Body19 from '/public/images/custom-avatar/body/body_19.svg?component';
import Body20 from '/public/images/custom-avatar/body/body_20.svg?component';
import Body21 from '/public/images/custom-avatar/body/body_21.svg?component';
import Body22 from '/public/images/custom-avatar/body/body_22.svg?component';
import Body23 from '/public/images/custom-avatar/body/body_23.svg?component';
import Body24 from '/public/images/custom-avatar/body/body_24.svg?component';
import Body25 from '/public/images/custom-avatar/body/body_25.svg?component';
import Body26 from '/public/images/custom-avatar/body/body_26.svg?component';
import Body27 from '/public/images/custom-avatar/body/body_27.svg?component';
import Body28 from '/public/images/custom-avatar/body/body_28.svg?component';
import Body29 from '/public/images/custom-avatar/body/body_29.svg?component';
import Body30 from '/public/images/custom-avatar/body/body_30.svg?component';
import Body31 from '/public/images/custom-avatar/body/body_31.svg?component';
import Body32 from '/public/images/custom-avatar/body/body_32.svg?component';
import Body33 from '/public/images/custom-avatar/body/body_33.svg?component';
import Body34 from '/public/images/custom-avatar/body/body_34.svg?component';
import Body35 from '/public/images/custom-avatar/body/body_35.svg?component';
import Body36 from '/public/images/custom-avatar/body/body_36.svg?component';
import Body37 from '/public/images/custom-avatar/body/body_37.svg?component';
import Body38 from '/public/images/custom-avatar/body/body_38.svg?component';
import Body39 from '/public/images/custom-avatar/body/body_39.svg?component';
import Body40 from '/public/images/custom-avatar/body/body_40.svg?component';
import Body41 from '/public/images/custom-avatar/body/body_41.svg?component';
import Body42 from '/public/images/custom-avatar/body/body_42.svg?component';
import Body43 from '/public/images/custom-avatar/body/body_43.svg?component';
import Body44 from '/public/images/custom-avatar/body/body_44.svg?component';
import Body45 from '/public/images/custom-avatar/body/body_45.svg?component';
import Body46 from '/public/images/custom-avatar/body/body_46.svg?component';
import Body47 from '/public/images/custom-avatar/body/body_47.svg?component';
import Body48 from '/public/images/custom-avatar/body/body_48.svg?component';
import Body49 from '/public/images/custom-avatar/body/body_49.svg?component';
import Body50 from '/public/images/custom-avatar/body/body_50.svg?component';
import Body51 from '/public/images/custom-avatar/body/body_51.svg?component';

import Body1Winter from '/public/images/custom-avatar/body/body_1-winter.svg?component';
import Body2Winter from '/public/images/custom-avatar/body/body_2-winter.svg?component';
import Body3Winter from '/public/images/custom-avatar/body/body_3-winter.svg?component';
import Body4Winter from '/public/images/custom-avatar/body/body_4-winter.svg?component';
import Body5Winter from '/public/images/custom-avatar/body/body_5-winter.svg?component';
import Body6Winter from '/public/images/custom-avatar/body/body_6-winter.svg?component';
import Body7Winter from '/public/images/custom-avatar/body/body_7-winter.svg?component';
import Body8Winter from '/public/images/custom-avatar/body/body_8-winter.svg?component';
import Body9Winter from '/public/images/custom-avatar/body/body_9-winter.svg?component';
import Body10Winter from '/public/images/custom-avatar/body/body_10-winter.svg?component';

import FullFace1Winter from '/public/images/custom-avatar/full-face/full-face_1-winter.svg?component';
import FullFace2Winter from '/public/images/custom-avatar/full-face/full-face_2-winter.svg?component';
import FullFace3Winter from '/public/images/custom-avatar/full-face/full-face_3-winter.svg?component';
import FullFace4Winter from '/public/images/custom-avatar/full-face/full-face_4-winter.svg?component';
import FullFace5Winter from '/public/images/custom-avatar/full-face/full-face_5-winter.svg?component';
import FullFace6Winter from '/public/images/custom-avatar/full-face/full-face_6-winter.svg?component';

// full face
import FullFace1 from '/public/images/custom-avatar/full-face/full-face_1.svg?component';
import FullFace2 from '/public/images/custom-avatar/full-face/full-face_2.svg?component';
import FullFace3 from '/public/images/custom-avatar/full-face/full-face_3.svg?component';
import FullFace4 from '/public/images/custom-avatar/full-face/full-face_4.svg?component';
import FullFace5 from '/public/images/custom-avatar/full-face/full-face_5.svg?component';
import FullFace6 from '/public/images/custom-avatar/full-face/full-face_6.svg?component';
import FullFace7 from '/public/images/custom-avatar/full-face/full-face_7.svg?component';
import FullFace8 from '/public/images/custom-avatar/full-face/full-face_8.svg?component';
import FullFace9 from '/public/images/custom-avatar/full-face/full-face_9.svg?component';
import FullFace10 from '/public/images/custom-avatar/full-face/full-face_10.svg?component';
import FullFace11 from '/public/images/custom-avatar/full-face/full-face_11.svg?component';
import FullFace12 from '/public/images/custom-avatar/full-face/full-face_12.svg?component';
import FullFace13 from '/public/images/custom-avatar/full-face/full-face_13.svg?component';
import FullFace14 from '/public/images/custom-avatar/full-face/full-face_14.svg?component';
import FullFace15 from '/public/images/custom-avatar/full-face/full-face_15.svg?component';
import FullFace16 from '/public/images/custom-avatar/full-face/full-face_16.svg?component';
import FullFace17 from '/public/images/custom-avatar/full-face/full-face_17.svg?component';
import FullFace18 from '/public/images/custom-avatar/full-face/full-face_18.svg?component';
import FullFace19 from '/public/images/custom-avatar/full-face/full-face_19.svg?component';
import FullFace20 from '/public/images/custom-avatar/full-face/full-face_20.svg?component';
import FullFace21 from '/public/images/custom-avatar/full-face/full-face_21.svg?component';
import FullFace22 from '/public/images/custom-avatar/full-face/full-face_22.svg?component';
import FullFace23 from '/public/images/custom-avatar/full-face/full-face_23.svg?component';
import FullFace24 from '/public/images/custom-avatar/full-face/full-face_24.svg?component';
import FullFace25 from '/public/images/custom-avatar/full-face/full-face_25.svg?component';
import FullFace26 from '/public/images/custom-avatar/full-face/full-face_26.svg?component';
import FullFace27 from '/public/images/custom-avatar/full-face/full-face_27.svg?component';
import FullFace28 from '/public/images/custom-avatar/full-face/full-face_28.svg?component';
import FullFace29 from '/public/images/custom-avatar/full-face/full-face_29.svg?component';
import FullFace30 from '/public/images/custom-avatar/full-face/full-face_30.svg?component';
import FullFace31 from '/public/images/custom-avatar/full-face/full-face_31.svg?component';
import FullFace32 from '/public/images/custom-avatar/full-face/full-face_32.svg?component';
import FullFace33 from '/public/images/custom-avatar/full-face/full-face_33.svg?component';
// hair front
import HairFront1 from '/public/images/custom-avatar/hair-front/hair-front_1.svg?component';
import HairFront2 from '/public/images/custom-avatar/hair-front/hair-front_2.svg?component';
import HairFront3 from '/public/images/custom-avatar/hair-front/hair-front_3.svg?component';
import HairFront4 from '/public/images/custom-avatar/hair-front/hair-front_4.svg?component';
import HairFront5 from '/public/images/custom-avatar/hair-front/hair-front_5.svg?component';
import HairFront6 from '/public/images/custom-avatar/hair-front/hair-front_6.svg?component';
import HairFront7 from '/public/images/custom-avatar/hair-front/hair-front_7.svg?component';
import HairFront8 from '/public/images/custom-avatar/hair-front/hair-front_8.svg?component';
import HairFront9 from '/public/images/custom-avatar/hair-front/hair-front_9.svg?component';
import HairFront10 from '/public/images/custom-avatar/hair-front/hair-front_10.svg?component';
import HairFront11 from '/public/images/custom-avatar/hair-front/hair-front_11.svg?component';
import HairFront12 from '/public/images/custom-avatar/hair-front/hair-front_12.svg?component';
import HairFront13 from '/public/images/custom-avatar/hair-front/hair-front_13.svg?component';
import HairFront14 from '/public/images/custom-avatar/hair-front/hair-front_14.svg?component';
import HairFront15 from '/public/images/custom-avatar/hair-front/hair-front_15.svg?component';
import HairFront16 from '/public/images/custom-avatar/hair-front/hair-front_16.svg?component';
import HairFront17 from '/public/images/custom-avatar/hair-front/hair-front_17.svg?component';
import HairFront18 from '/public/images/custom-avatar/hair-front/hair-front_18.svg?component';
import HairFront19 from '/public/images/custom-avatar/hair-front/hair-front_19.svg?component';
import HairFront20 from '/public/images/custom-avatar/hair-front/hair-front_20.svg?component';
import HairFront21 from '/public/images/custom-avatar/hair-front/hair-front_21.svg?component';
import HairFront22 from '/public/images/custom-avatar/hair-front/hair-front_22.svg?component';
import HairFront23 from '/public/images/custom-avatar/hair-front/hair-front_23.svg?component';
import HairFront24 from '/public/images/custom-avatar/hair-front/hair-front_24.svg?component';
import HairFront25 from '/public/images/custom-avatar/hair-front/hair-front_25.svg?component';
import HairFront26 from '/public/images/custom-avatar/hair-front/hair-front_26.svg?component';
import HairFront27 from '/public/images/custom-avatar/hair-front/hair-front_27.svg?component';
import HairFront28 from '/public/images/custom-avatar/hair-front/hair-front_28.svg?component';
import HairFront29 from '/public/images/custom-avatar/hair-front/hair-front_29.svg?component';
import HairFront30 from '/public/images/custom-avatar/hair-front/hair-front_30.svg?component';
import HairFront31 from '/public/images/custom-avatar/hair-front/hair-front_31.svg?component';
import HairFront32 from '/public/images/custom-avatar/hair-front/hair-front_32.svg?component';
import HairFront33 from '/public/images/custom-avatar/hair-front/hair-front_33.svg?component';
import HairFront34 from '/public/images/custom-avatar/hair-front/hair-front_34.svg?component';
import HairFront35 from '/public/images/custom-avatar/hair-front/hair-front_35.svg?component';
import HairFront36 from '/public/images/custom-avatar/hair-front/hair-front_36.svg?component';
import HairFront37 from '/public/images/custom-avatar/hair-front/hair-front_37.svg?component';
import HairFront38 from '/public/images/custom-avatar/hair-front/hair-front_38.svg?component';
import HairFront39 from '/public/images/custom-avatar/hair-front/hair-front_39.svg?component';
import HairFront40 from '/public/images/custom-avatar/hair-front/hair-front_40.svg?component';
// hair back
import HairBack2 from '/public/images/custom-avatar/hair-back/hair-back_2.svg?component';
import HairBack8 from '/public/images/custom-avatar/hair-back/hair-back_8.svg?component';
import HairBack10 from '/public/images/custom-avatar/hair-back/hair-back_10.svg?component';
import HairBack13 from '/public/images/custom-avatar/hair-back/hair-back_13.svg?component';
import HairBack14 from '/public/images/custom-avatar/hair-back/hair-back_14.svg?component';
import HairBack15 from '/public/images/custom-avatar/hair-back/hair-back_15.svg?component';
import HairBack16 from '/public/images/custom-avatar/hair-back/hair-back_16.svg?component';
import HairBack19 from '/public/images/custom-avatar/hair-back/hair-back_19.svg?component';
import HairBack20 from '/public/images/custom-avatar/hair-back/hair-back_20.svg?component';
import HairBack21 from '/public/images/custom-avatar/hair-back/hair-back_21.svg?component';
import HairBack23 from '/public/images/custom-avatar/hair-back/hair-back_23.svg?component';
import HairBack31 from '/public/images/custom-avatar/hair-back/hair-back_31.svg?component';
import HairBack32 from '/public/images/custom-avatar/hair-back/hair-back_32.svg?component';
import HairBack33 from '/public/images/custom-avatar/hair-back/hair-back_33.svg?component';
import HairBack34 from '/public/images/custom-avatar/hair-back/hair-back_34.svg?component';
import HairBack35 from '/public/images/custom-avatar/hair-back/hair-back_35.svg?component';
import HairBack36 from '/public/images/custom-avatar/hair-back/hair-back_36.svg?component';

import Headgear1Winter from '/public/images/custom-avatar/headgear/headgear_1-winter.svg?component';
import Headgear2Winter from '/public/images/custom-avatar/headgear/headgear_2-winter.svg?component';
import Headgear3Winter from '/public/images/custom-avatar/headgear/headgear_3-winter.svg?component';
import Headgear4Winter from '/public/images/custom-avatar/headgear/headgear_4-winter.svg?component';
import Headgear5Winter from '/public/images/custom-avatar/headgear/headgear_5-winter.svg?component';
import Headgear6Winter from '/public/images/custom-avatar/headgear/headgear_6-winter.svg?component';
import Headgear7Winter from '/public/images/custom-avatar/headgear/headgear_7-winter.svg?component';
import Headgear8Winter from '/public/images/custom-avatar/headgear/headgear_8-winter.svg?component';
import Headgear9Winter from '/public/images/custom-avatar/headgear/headgear_9-winter.svg?component';

// headgear
import Headgear1 from '/public/images/custom-avatar/headgear/headgear_1.svg?component';
import Headgear2 from '/public/images/custom-avatar/headgear/headgear_2.svg?component';
import Headgear3 from '/public/images/custom-avatar/headgear/headgear_3.svg?component';
import Headgear4 from '/public/images/custom-avatar/headgear/headgear_4.svg?component';
import Headgear5 from '/public/images/custom-avatar/headgear/headgear_5.svg?component';
import Headgear6 from '/public/images/custom-avatar/headgear/headgear_6.svg?component';
import Headgear7 from '/public/images/custom-avatar/headgear/headgear_7.svg?component';
import Headgear8 from '/public/images/custom-avatar/headgear/headgear_8.svg?component';
import Headgear9 from '/public/images/custom-avatar/headgear/headgear_9.svg?component';
import Headgear10 from '/public/images/custom-avatar/headgear/headgear_10.svg?component';
import Headgear11 from '/public/images/custom-avatar/headgear/headgear_11.svg?component';
import Headgear12 from '/public/images/custom-avatar/headgear/headgear_12.svg?component';
import Headgear13 from '/public/images/custom-avatar/headgear/headgear_13.svg?component';
import Headgear14 from '/public/images/custom-avatar/headgear/headgear_14.svg?component';
import Headgear15 from '/public/images/custom-avatar/headgear/headgear_15.svg?component';
import Headgear16 from '/public/images/custom-avatar/headgear/headgear_16.svg?component';
import Headgear17 from '/public/images/custom-avatar/headgear/headgear_17.svg?component';
import Headgear18 from '/public/images/custom-avatar/headgear/headgear_18.svg?component';
import Headgear19 from '/public/images/custom-avatar/headgear/headgear_19.svg?component';
import Headgear20 from '/public/images/custom-avatar/headgear/headgear_20.svg?component';
import Headgear21 from '/public/images/custom-avatar/headgear/headgear_21.svg?component';
import Headgear22 from '/public/images/custom-avatar/headgear/headgear_22.svg?component';
import Headgear23 from '/public/images/custom-avatar/headgear/headgear_23.svg?component';
import Headgear24 from '/public/images/custom-avatar/headgear/headgear_24.svg?component';
import Headgear25 from '/public/images/custom-avatar/headgear/headgear_25.svg?component';
import Headgear26 from '/public/images/custom-avatar/headgear/headgear_26.svg?component';
import Headgear27 from '/public/images/custom-avatar/headgear/headgear_27.svg?component';
import Headgear28 from '/public/images/custom-avatar/headgear/headgear_28.svg?component';
import Headgear29 from '/public/images/custom-avatar/headgear/headgear_29.svg?component';
import Headgear30 from '/public/images/custom-avatar/headgear/headgear_30.svg?component';
import Headgear31 from '/public/images/custom-avatar/headgear/headgear_31.svg?component';
import Headgear32 from '/public/images/custom-avatar/headgear/headgear_32.svg?component';
import Headgear33 from '/public/images/custom-avatar/headgear/headgear_33.svg?component';
import Headgear34 from '/public/images/custom-avatar/headgear/headgear_34.svg?component';
import Headgear35 from '/public/images/custom-avatar/headgear/headgear_35.svg?component';
import Headgear36 from '/public/images/custom-avatar/headgear/headgear_36.svg?component';
import Headgear37 from '/public/images/custom-avatar/headgear/headgear_37.svg?component';
import Headgear38 from '/public/images/custom-avatar/headgear/headgear_38.svg?component';
// brows
import Brows1 from '/public/images/custom-avatar/brows/brows_1.svg?component';
import Brows2 from '/public/images/custom-avatar/brows/brows_2.svg?component';
import Brows3 from '/public/images/custom-avatar/brows/brows_3.svg?component';
import Brows4 from '/public/images/custom-avatar/brows/brows_4.svg?component';
import Brows5 from '/public/images/custom-avatar/brows/brows_5.svg?component';
import Brows6 from '/public/images/custom-avatar/brows/brows_6.svg?component';
import Brows7 from '/public/images/custom-avatar/brows/brows_7.svg?component';
import Brows8 from '/public/images/custom-avatar/brows/brows_8.svg?component';
import Brows9 from '/public/images/custom-avatar/brows/brows_9.svg?component';
import Brows10 from '/public/images/custom-avatar/brows/brows_10.svg?component';
import Brows11 from '/public/images/custom-avatar/brows/brows_11.svg?component';
import Brows12 from '/public/images/custom-avatar/brows/brows_12.svg?component';
import Brows13 from '/public/images/custom-avatar/brows/brows_13.svg?component';
import Brows14 from '/public/images/custom-avatar/brows/brows_14.svg?component';
import Brows15 from '/public/images/custom-avatar/brows/brows_15.svg?component';
import Brows16 from '/public/images/custom-avatar/brows/brows_16.svg?component';
import Brows17 from '/public/images/custom-avatar/brows/brows_17.svg?component';
// eyes
import Eyes1 from '/public/images/custom-avatar/eyes/eyes_1.svg?component';
import Eyes2 from '/public/images/custom-avatar/eyes/eyes_2.svg?component';
import Eyes3 from '/public/images/custom-avatar/eyes/eyes_3.svg?component';
import Eyes4 from '/public/images/custom-avatar/eyes/eyes_4.svg?component';
import Eyes5 from '/public/images/custom-avatar/eyes/eyes_5.svg?component';
import Eyes6 from '/public/images/custom-avatar/eyes/eyes_6.svg?component';
import Eyes7 from '/public/images/custom-avatar/eyes/eyes_7.svg?component';
import Eyes8 from '/public/images/custom-avatar/eyes/eyes_8.svg?component';
import Eyes9 from '/public/images/custom-avatar/eyes/eyes_9.svg?component';
import Eyes10 from '/public/images/custom-avatar/eyes/eyes_10.svg?component';
import Eyes11 from '/public/images/custom-avatar/eyes/eyes_11.svg?component';
import Eyes12 from '/public/images/custom-avatar/eyes/eyes_12.svg?component';
import Eyes13 from '/public/images/custom-avatar/eyes/eyes_13.svg?component';
import Eyes14 from '/public/images/custom-avatar/eyes/eyes_14.svg?component';
import Eyes15 from '/public/images/custom-avatar/eyes/eyes_15.svg?component';
import Eyes16 from '/public/images/custom-avatar/eyes/eyes_16.svg?component';
import Eyes17 from '/public/images/custom-avatar/eyes/eyes_17.svg?component';
import Eyes18 from '/public/images/custom-avatar/eyes/eyes_18.svg?component';
import Eyes19 from '/public/images/custom-avatar/eyes/eyes_19.svg?component';
import Eyes20 from '/public/images/custom-avatar/eyes/eyes_20.svg?component';
import Eyes21 from '/public/images/custom-avatar/eyes/eyes_21.svg?component';
import Eyes22 from '/public/images/custom-avatar/eyes/eyes_22.svg?component';
import Eyes23 from '/public/images/custom-avatar/eyes/eyes_23.svg?component';
import Eyes24 from '/public/images/custom-avatar/eyes/eyes_24.svg?component';
import Eyes25 from '/public/images/custom-avatar/eyes/eyes_25.svg?component';
import Eyes26 from '/public/images/custom-avatar/eyes/eyes_26.svg?component';
import Eyes27 from '/public/images/custom-avatar/eyes/eyes_27.svg?component';
import Eyes28 from '/public/images/custom-avatar/eyes/eyes_28.svg?component';
import Eyes29 from '/public/images/custom-avatar/eyes/eyes_29.svg?component';
import Eyes30 from '/public/images/custom-avatar/eyes/eyes_30.svg?component';
import Eyes31 from '/public/images/custom-avatar/eyes/eyes_31.svg?component';
import Eyes32 from '/public/images/custom-avatar/eyes/eyes_32.svg?component';
import Eyes33 from '/public/images/custom-avatar/eyes/eyes_33.svg?component';
import Eyes34 from '/public/images/custom-avatar/eyes/eyes_34.svg?component';
import Eyes35 from '/public/images/custom-avatar/eyes/eyes_35.svg?component';
import Eyes36 from '/public/images/custom-avatar/eyes/eyes_36.svg?component';
import Eyes37 from '/public/images/custom-avatar/eyes/eyes_37.svg?component';
import Eyes38 from '/public/images/custom-avatar/eyes/eyes_38.svg?component';
import Eyes39 from '/public/images/custom-avatar/eyes/eyes_39.svg?component';
import Eyes40 from '/public/images/custom-avatar/eyes/eyes_40.svg?component';
import Eyes41 from '/public/images/custom-avatar/eyes/eyes_41.svg?component';
import Eyes42 from '/public/images/custom-avatar/eyes/eyes_42.svg?component';
import Eyes43 from '/public/images/custom-avatar/eyes/eyes_43.svg?component';

import Eyewear1Winter from '/public/images/custom-avatar/eyewear/eyewear_1-winter.svg?component';
import Eyewear2Winter from '/public/images/custom-avatar/eyewear/eyewear_2-winter.svg?component';
import Eyewear3Winter from '/public/images/custom-avatar/eyewear/eyewear_3-winter.svg?component';
import Eyewear4Winter from '/public/images/custom-avatar/eyewear/eyewear_4-winter.svg?component';
import Eyewear5Winter from '/public/images/custom-avatar/eyewear/eyewear_5-winter.svg?component';

// eyewear
import Eyewear1 from '/public/images/custom-avatar/eyewear/eyewear_1.svg?component';
import Eyewear2 from '/public/images/custom-avatar/eyewear/eyewear_2.svg?component';
import Eyewear3 from '/public/images/custom-avatar/eyewear/eyewear_3.svg?component';
import Eyewear4 from '/public/images/custom-avatar/eyewear/eyewear_4.svg?component';
import Eyewear5 from '/public/images/custom-avatar/eyewear/eyewear_5.svg?component';
import Eyewear6 from '/public/images/custom-avatar/eyewear/eyewear_6.svg?component';
import Eyewear7 from '/public/images/custom-avatar/eyewear/eyewear_7.svg?component';
import Eyewear8 from '/public/images/custom-avatar/eyewear/eyewear_8.svg?component';
import Eyewear9 from '/public/images/custom-avatar/eyewear/eyewear_9.svg?component';
import Eyewear10 from '/public/images/custom-avatar/eyewear/eyewear_10.svg?component';
import Eyewear11 from '/public/images/custom-avatar/eyewear/eyewear_11.svg?component';
import Eyewear12 from '/public/images/custom-avatar/eyewear/eyewear_12.svg?component';
import Eyewear13 from '/public/images/custom-avatar/eyewear/eyewear_13.svg?component';
import Eyewear14 from '/public/images/custom-avatar/eyewear/eyewear_14.svg?component';
import Eyewear15 from '/public/images/custom-avatar/eyewear/eyewear_15.svg?component';
import Eyewear16 from '/public/images/custom-avatar/eyewear/eyewear_16.svg?component';
import Eyewear17 from '/public/images/custom-avatar/eyewear/eyewear_17.svg?component';
import Eyewear18 from '/public/images/custom-avatar/eyewear/eyewear_18.svg?component';
import Eyewear19 from '/public/images/custom-avatar/eyewear/eyewear_19.svg?component';
import Eyewear20 from '/public/images/custom-avatar/eyewear/eyewear_20.svg?component';
import Eyewear21 from '/public/images/custom-avatar/eyewear/eyewear_21.svg?component';
import Eyewear22 from '/public/images/custom-avatar/eyewear/eyewear_22.svg?component';
import Eyewear23 from '/public/images/custom-avatar/eyewear/eyewear_23.svg?component';
import Eyewear24 from '/public/images/custom-avatar/eyewear/eyewear_24.svg?component';
// mouth
import Mouth1 from '/public/images/custom-avatar/mouth/mouth_1.svg?component';
import Mouth2 from '/public/images/custom-avatar/mouth/mouth_2.svg?component';
import Mouth3 from '/public/images/custom-avatar/mouth/mouth_3.svg?component';
import Mouth4 from '/public/images/custom-avatar/mouth/mouth_4.svg?component';
import Mouth5 from '/public/images/custom-avatar/mouth/mouth_5.svg?component';
import Mouth6 from '/public/images/custom-avatar/mouth/mouth_6.svg?component';
import Mouth7 from '/public/images/custom-avatar/mouth/mouth_7.svg?component';
import Mouth8 from '/public/images/custom-avatar/mouth/mouth_8.svg?component';
import Mouth9 from '/public/images/custom-avatar/mouth/mouth_9.svg?component';
import Mouth10 from '/public/images/custom-avatar/mouth/mouth_10.svg?component';
import Mouth11 from '/public/images/custom-avatar/mouth/mouth_11.svg?component';
import Mouth12 from '/public/images/custom-avatar/mouth/mouth_12.svg?component';
import Mouth13 from '/public/images/custom-avatar/mouth/mouth_13.svg?component';
import Mouth14 from '/public/images/custom-avatar/mouth/mouth_14.svg?component';
import Mouth15 from '/public/images/custom-avatar/mouth/mouth_15.svg?component';
import Mouth16 from '/public/images/custom-avatar/mouth/mouth_16.svg?component';
import Mouth17 from '/public/images/custom-avatar/mouth/mouth_17.svg?component';

export const FULL_FACE = {
    1: FullFace1,
    2: FullFace2,
    3: FullFace3,
    4: FullFace4,
    5: FullFace5,
    6: FullFace6,
    7: FullFace7,
    8: FullFace8,
    9: FullFace9,
    10: FullFace10,
    11: FullFace11,
    12: FullFace12,
    13: FullFace13,
    14: FullFace14,
    15: FullFace15,
    16: FullFace16,
    17: FullFace17,
    18: FullFace18,
    19: FullFace19,
    20: FullFace20,
    21: FullFace21,
    22: FullFace22,
    23: FullFace23,
    24: FullFace24,
    25: FullFace25,
    26: FullFace26,
    27: FullFace27,
    28: FullFace28,
    29: FullFace29,
    30: FullFace30,
    31: FullFace31,
    32: FullFace32,
    33: FullFace33,
    '1-winter': FullFace1Winter,
    '2-winter': FullFace2Winter,
    '3-winter': FullFace3Winter,
    '4-winter': FullFace4Winter,
    '5-winter': FullFace5Winter,
    '6-winter': FullFace6Winter,
};

export const HAIR_FRONT = {
    1: HairFront1,
    2: HairFront2,
    3: HairFront3,
    4: HairFront4,
    5: HairFront5,
    6: HairFront6,
    7: HairFront7,
    8: HairFront8,
    9: HairFront9,
    10: HairFront10,
    11: HairFront11,
    12: HairFront12,
    13: HairFront13,
    14: HairFront14,
    15: HairFront15,
    16: HairFront16,
    17: HairFront17,
    18: HairFront18,
    19: HairFront19,
    20: HairFront20,
    21: HairFront21,
    22: HairFront22,
    23: HairFront23,
    24: HairFront24,
    25: HairFront25,
    26: HairFront26,
    27: HairFront27,
    28: HairFront28,
    29: HairFront29,
    30: HairFront30,
    31: HairFront31,
    32: HairFront32,
    33: HairFront33,
    34: HairFront34,
    35: HairFront35,
    36: HairFront36,
    37: HairFront37,
    38: HairFront38,
    39: HairFront39,
    40: HairFront40,
};

export const HAIR_BACK = {
    2: HairBack2,
    8: HairBack8,
    10: HairBack10,
    13: HairBack13,
    14: HairBack14,
    15: HairBack15,
    16: HairBack16,
    19: HairBack19,
    20: HairBack20,
    21: HairBack21,
    23: HairBack23,
    31: HairBack31,
    32: HairBack32,
    33: HairBack33,
    34: HairBack34,
    35: HairBack35,
    36: HairBack36,
};

export const HEADGEAR = {
    1: Headgear1,
    2: Headgear2,
    3: Headgear3,
    4: Headgear4,
    5: Headgear5,
    6: Headgear6,
    7: Headgear7,
    8: Headgear8,
    9: Headgear9,
    10: Headgear10,
    11: Headgear11,
    12: Headgear12,
    13: Headgear13,
    14: Headgear14,
    15: Headgear15,
    16: Headgear16,
    17: Headgear17,
    18: Headgear18,
    19: Headgear19,
    20: Headgear20,
    21: Headgear21,
    22: Headgear22,
    23: Headgear23,
    24: Headgear24,
    25: Headgear25,
    26: Headgear26,
    27: Headgear27,
    28: Headgear28,
    29: Headgear29,
    30: Headgear30,
    31: Headgear31,
    32: Headgear32,
    33: Headgear33,
    34: Headgear34,
    35: Headgear35,
    36: Headgear36,
    37: Headgear37,
    38: Headgear38,
    '1-winter': Headgear1Winter,
    '2-winter': Headgear2Winter,
    '3-winter': Headgear3Winter,
    '4-winter': Headgear4Winter,
    '5-winter': Headgear5Winter,
    '6-winter': Headgear6Winter,
    '7-winter': Headgear7Winter,
    '8-winter': Headgear8Winter,
    '9-winter': Headgear9Winter,
};

export const BROWS = {
    1: Brows1,
    2: Brows2,
    3: Brows3,
    4: Brows4,
    5: Brows5,
    6: Brows6,
    7: Brows7,
    8: Brows8,
    9: Brows9,
    10: Brows10,
    11: Brows11,
    12: Brows12,
    13: Brows13,
    14: Brows14,
    15: Brows15,
    16: Brows16,
    17: Brows17,
};

export const EYES = {
    1: Eyes1,
    2: Eyes2,
    3: Eyes3,
    4: Eyes4,
    5: Eyes5,
    6: Eyes6,
    7: Eyes7,
    8: Eyes8,
    9: Eyes9,
    10: Eyes10,
    11: Eyes11,
    12: Eyes12,
    13: Eyes13,
    14: Eyes14,
    15: Eyes15,
    16: Eyes16,
    17: Eyes17,
    18: Eyes18,
    19: Eyes19,
    20: Eyes20,
    21: Eyes21,
    22: Eyes22,
    23: Eyes23,
    24: Eyes24,
    25: Eyes25,
    26: Eyes26,
    27: Eyes27,
    28: Eyes28,
    29: Eyes29,
    30: Eyes30,
    31: Eyes31,
    32: Eyes32,
    33: Eyes33,
    34: Eyes34,
    35: Eyes35,
    36: Eyes36,
    37: Eyes37,
    38: Eyes38,
    39: Eyes39,
    40: Eyes40,
    41: Eyes41,
    42: Eyes42,
    43: Eyes43,
};

export const EYEWEAR = {
    1: Eyewear1,
    2: Eyewear2,
    3: Eyewear3,
    4: Eyewear4,
    5: Eyewear5,
    6: Eyewear6,
    7: Eyewear7,
    8: Eyewear8,
    9: Eyewear9,
    10: Eyewear10,
    11: Eyewear11,
    12: Eyewear12,
    13: Eyewear13,
    14: Eyewear14,
    15: Eyewear15,
    16: Eyewear16,
    17: Eyewear17,
    18: Eyewear18,
    19: Eyewear19,
    20: Eyewear20,
    21: Eyewear21,
    22: Eyewear22,
    23: Eyewear23,
    24: Eyewear24,
    '1-winter': Eyewear1Winter,
    '2-winter': Eyewear2Winter,
    '3-winter': Eyewear3Winter,
    '4-winter': Eyewear4Winter,
    '5-winter': Eyewear5Winter,
};
export const MOUTH = {
    1: Mouth1,
    2: Mouth2,
    3: Mouth3,
    4: Mouth4,
    5: Mouth5,
    6: Mouth6,
    7: Mouth7,
    8: Mouth8,
    9: Mouth9,
    10: Mouth10,
    11: Mouth11,
    12: Mouth12,
    13: Mouth13,
    14: Mouth14,
    15: Mouth15,
    16: Mouth16,
    17: Mouth17,
};

export const BODY = {
    1: Body1,
    2: Body2,
    3: Body3,
    4: Body4,
    5: Body5,
    6: Body6,
    7: Body7,
    8: Body8,
    9: Body9,
    10: Body10,
    11: Body11,
    12: Body12,
    13: Body13,
    14: Body14,
    15: Body15,
    16: Body16,
    17: Body17,
    18: Body18,
    19: Body19,
    20: Body20,
    21: Body21,
    22: Body22,
    23: Body23,
    24: Body24,
    25: Body25,
    26: Body26,
    27: Body27,
    28: Body28,
    29: Body29,
    30: Body30,
    31: Body31,
    32: Body32,
    33: Body33,
    34: Body34,
    35: Body35,
    36: Body36,
    37: Body37,
    38: Body38,
    39: Body39,
    40: Body40,
    41: Body41,
    42: Body42,
    43: Body43,
    44: Body44,
    45: Body45,
    46: Body46,
    47: Body47,
    48: Body48,
    49: Body49,
    50: Body50,
    51: Body51,
    '1-winter': Body1Winter,
    '2-winter': Body2Winter,
    '3-winter': Body3Winter,
    '4-winter': Body4Winter,
    '5-winter': Body5Winter,
    '6-winter': Body6Winter,
    '7-winter': Body7Winter,
    '8-winter': Body8Winter,
    '9-winter': Body9Winter,
    '10-winter': Body10Winter,
};

export const CustomAvatarImports = {
    components: {
        Head1,
        Body1,
        Body2,
        Body3,
        Body4,
        Body5,
        Body6,
        Body7,
        Body8,
        Body9,
        Body10,
        Body11,
        Body12,
        Body13,
        Body14,
        Body15,
        Body16,
        Body17,
        Body18,
        Body19,
        Body20,
        Body21,
        Body22,
        Body23,
        Body24,
        Body25,
        Body26,
        Body27,
        Body28,
        Body29,
        Body30,
        Body31,
        Body32,
        Body33,
        Body34,
        Body1Winter,
        Body2Winter,
        Body3Winter,
        Body4Winter,
        Body5Winter,
        Body6Winter,
        Body7Winter,
        Body8Winter,
        Body9Winter,
        Body10Winter,
        FullFace1,
        FullFace2,
        FullFace3,
        FullFace4,
        FullFace5,
        FullFace6,
        FullFace7,
        FullFace8,
        FullFace9,
        FullFace10,
        FullFace11,
        FullFace12,
        FullFace13,
        FullFace14,
        FullFace15,
        FullFace16,
        FullFace17,
        FullFace18,
        FullFace19,
        FullFace20,
        FullFace1Winter,
        FullFace2Winter,
        FullFace3Winter,
        FullFace4Winter,
        FullFace5Winter,
        FullFace6Winter,
        HairFront1,
        HairFront2,
        HairFront3,
        HairFront4,
        HairFront5,
        HairFront6,
        HairFront7,
        HairFront8,
        HairFront9,
        HairFront10,
        HairFront11,
        HairFront12,
        HairFront13,
        HairFront14,
        HairFront15,
        HairFront16,
        HairBack2,
        HairBack8,
        HairBack10,
        HairBack13,
        HairBack14,
        HairBack15,
        HairBack16,
        Headgear1,
        Headgear2,
        Headgear3,
        Headgear4,
        Headgear5,
        Headgear6,
        Headgear7,
        Headgear8,
        Headgear9,
        Headgear10,
        Headgear11,
        Headgear12,
        Headgear13,
        Headgear14,
        Headgear15,
        Headgear16,
        Headgear17,
        Headgear18,
        Headgear19,
        Headgear20,
        Headgear21,
        Headgear22,
        Headgear23,
        Headgear24,
        Headgear25,
        Headgear1Winter,
        Headgear2Winter,
        Headgear3Winter,
        Headgear4Winter,
        Headgear5Winter,
        Headgear6Winter,
        Headgear7Winter,
        Headgear8Winter,
        Headgear9Winter,
        Brows1,
        Brows2,
        Brows3,
        Brows4,
        Brows5,
        Brows6,
        Brows7,
        Brows8,
        Brows9,
        Brows10,
        Brows11,
        Brows12,
        Brows13,
        Brows14,
        Brows15,
        Brows16,
        Brows17,
        Eyes1,
        Eyes2,
        Eyes3,
        Eyes4,
        Eyes5,
        Eyes6,
        Eyes7,
        Eyes8,
        Eyes9,
        Eyes10,
        Eyes11,
        Eyes12,
        Eyes13,
        Eyes14,
        Eyes15,
        Eyes16,
        Eyes17,
        Eyes18,
        Eyes19,
        Eyes20,
        Eyes21,
        Eyes22,
        Eyes23,
        Eyes24,
        Eyes25,
        Eyes26,
        Eyes27,
        Eyes28,
        Eyes29,
        Eyewear1,
        Eyewear2,
        Eyewear3,
        Eyewear4,
        Eyewear1Winter,
        Eyewear2Winter,
        Eyewear3Winter,
        Eyewear4Winter,
        Eyewear5Winter,
        Mouth1,
        Mouth2,
        Mouth3,
        Mouth4,
        Mouth5,
        Mouth6,
        Mouth7,
        Mouth8,
        Mouth9,
        Mouth10,
        Mouth11,
        Mouth12,
        Mouth13,
        Mouth14,
        Mouth15,
        Mouth16,
        Mouth17,
    },
};
