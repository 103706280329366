/* global mixpanel */
export const BRAIN_BREAK_EVENTS = {
    SEES_BRAIN_BREAK_GAME_INTRO_SCREEN: 'seesBrainBreakGameIntroScreen',
    SEES_STACK_BRAIN_BREAK_GAME: 'seesStackBrainBreakGame',
    START_STACK_BRAIN_BREAK_GAME: 'startStackBrainBreakGame',
    FINISHED_STACK_BRAIN_BREAK_GAME: 'finishedStackBrainBreakGame',
    SEES_CROSS_ROAD_BRAIN_BREAK_GAME: 'seesCrossRoadBrainBreakGame',
    START_CROSS_ROAD_BRAIN_BREAK_GAME: 'startCrossRoadBrainBreakGame',
    FINISHED_CROSS_ROAD_BRAIN_BREAK_GAME: 'finishedCrossRoadBrainBreakGame',
};

export const BRAIN_BREAK_METHODS = {
    seesBrainBreakGameIntroScreen() {
        mixpanel.track('Sees brain break game intro screen');
    },
    seesStackBrainBreakGame() {
        // mixpanel.track('Sees stack brain break game');
    },
    startStackBrainBreakGame() {
        // mixpanel.track('Start stack brain break game');
    },
    finishedStackBrainBreakGame() {
        // mixpanel.track('Finished stack brain break game');
    },
    seesCrossRoadBrainBreakGame() {
        // mixpanel.track('Sees cross road brain break game');
    },
    startCrossRoadBrainBreakGame() {
        // mixpanel.track('Start cross road brain break game');
    },
    finishedCrossRoadBrainBreakGame() {
        // mixpanel.track('Finished cross road brain break game');
    },
};
