import { capitalize } from '@/core/helpers/utils.js';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService.js';
import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import _ from 'lodash';
import moment from 'moment';

function reportError(message, error, callback) {
    console.error(message, error);
}

export function getGradeEnding(grade) {
    switch (grade) {
        case '1':
            return 'st';
        case '2':
            return 'nd';
        case '3':
            return 'rd';
        case 'K':
            return '';
        default:
            return 'th';
    }
}

export default {
    namespaced: true,
    state: {
        teams: [],
        leaderboard: [],
        competitionClassCode: undefined,
        competitionTeam: null,
        customGrade: null,
    },
    getters: {
        getCompetitionGrade: (state) => {
            return state.customGrade || state.competitionTeam?.grade;
        },
        getCompetitionGradeLabel: (state, getters) => {
            const grade = getters.getCompetitionGrade;
            if (grade) {
                const ending = getGradeEnding(grade);
                return `${grade}${ending} Grade`;
            } else {
                return '';
            }
        },
        leaderboardFilteredCount: (state) => {
            return state.leaderboard[0]?.filteredParticipants || 0;
        },
        leaderboardTotalCount: (state) => {
            return state.leaderboard[0]?.totalParticipants || 0;
        },
        leaderboardUpdateTime: (state) => {
            return state.leaderboard[0]?.lastRankComputedAt;
        },
        leaderboardUpdateText: (state, getters) => () => {
            const time = moment
                .duration(moment().diff(getters.leaderboardUpdateTime))
                .humanize();
            return `Last updated ${time} ago`;
        },
    },
    mutations: {
        addOrUpdateTeam: (state, team) => {
            const pos = state.teams.findIndex(
                (t) => t.classCode === team.classCode,
            );
            if (pos > -1) state.teams[pos] = team;
            else state.teams.push(team);
        },
        setTeams: (state, teams) => {
            state.teams = teams;
            if (state.competitionClassCode) {
                state.competitionTeam = state.teams.find(
                    (team) => team.classCode === state.competitionClassCode,
                );
            }
        },
        setLeaderboard: (state, leaderboard) => {
            // format score
            function formatScore(score) {
                return !score || score === 0 ? 0 : score.toFixed(2);
            }

            state.leaderboard = leaderboard.map((item) => {
                return {
                    ...item,
                    schoolScore: formatScore(item.schoolScore),
                    homeScore: formatScore(item.homeScore),
                    totalScore: formatScore(item.totalScore),
                };
            });
        },
        setCompetitionClassCode: (state, classCode) => {
            state.competitionClassCode = classCode;
            state.competitionTeam = state.teams.find(
                (team) => team.classCode === classCode,
            );
            if (classCode) {
                state.customGrade = null;
            }
        },
        setCustomGrade: (state, grade) => {
            state.customGrade = grade;
        },
    },
    actions: {
        fetchAllCompetitionEntries: async ({ commit }) => {
            try {
                const result = await SecureApi().get(
                    `competitions/registered-entries`,
                );
                if (!result.data.success) {
                    reportError(
                        'Error reading all competition entries',
                        result,
                    );
                }

                return result.data.data;
            } catch (error) {
                reportError('Error reading all competition entries', error);
            }
        },
        fetchTeams: async ({ commit }, payload) => {
            const competitionId = payload?.competitionId || null;
            let queryString = '?';
            if (competitionId) {
                queryString += `competitionId=${competitionId}`;
            }
            try {
                const result = await SecureApi().get(
                    `competitions${queryString}`,
                );
                if (!result.data.success) {
                    reportError(
                        'Error reading competition teams',
                        result,
                        () => {
                            commit('setTeams', []);
                        },
                    );
                }

                commit('setTeams', result.data.data);
            } catch (error) {
                reportError('Error reading competition teams', error, () => {
                    commit('setTeams', []);
                });
            }
        },

        fetchLeaderboard: async ({ state, getters, commit }, payload) => {
            const grade = payload.grade || getters.getCompetitionGrade;
            if (!grade) {
                console.warn(
                    `Class ${state.competitionClassCode} is not registered for the competition`,
                    state.teams,
                    grade,
                );
                commit('setLeaderboard', []);
                return;
            }

            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            grade,
                            ..._.pick(payload, [
                                'state',
                                'school',
                                'lastPosition',
                                'moveUp',
                            ]),
                            participantId: payload.classCode,
                            pageSize: payload.pageSize ?? 10,
                        },
                    },
                );
                if (result.data.success) {
                    commit('setLeaderboard', result.data.data);
                } else {
                    reportError(
                        'Error reading competition leaderboard',
                        result,
                        () => {
                            commit('setLeaderboard', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition leaderboard',
                    error,
                    () => {
                        commit('setLeaderboard', []);
                    },
                );
            }
        },

        registerTeam: async ({ state, commit }, { classCode, formData }) => {
            let result, errorMessage;
            try {
                result = await SecureApi().post('competitions/register', {
                    classCode: classCode,
                    grade: formData.grade,
                    state: formData.state,
                    school: capitalize(formData.schoolName),
                    publicName: formData.publicName,
                });
            } catch (error) {
                errorMessage = error?.message;
                return {
                    success: false,
                    errorMessage,
                };
            }

            const { success, error, data: response } = result.data;
            if (success) {
                new TrackingService().track(
                    EVENTS.MONTHLY_COMPETITION_REGISTERS_CLASS,
                    {
                        classCode: classCode,
                        grade: formData.grade,
                        state: formData.state,
                        schoolName: capitalize(formData.schoolName),
                        publicClassName: formData.publicName,
                    },
                );
                commit('addOrUpdateTeam', response);
            } else {
                errorMessage = error?.message?.message;
            }

            return {
                success,
                errorMessage,
            };
        },

        editTeam: async ({ state, commit }, { classCode, formData }) => {
            let result, errorMessage;
            try {
                result = await SecureApi().patch('competitions/edit', {
                    classCode: classCode,
                    school: capitalize(formData.schoolName),
                    publicName: formData.publicName,
                });
            } catch (error) {
                errorMessage = error?.message;
                return {
                    success: false,
                    errorMessage,
                };
            }

            const { success, error, data: response } = result.data;
            if (success) {
                new TrackingService().track(
                    EVENTS.MONTHLY_COMPETITION_EDITS_CLASS,
                    {
                        classCode: classCode,
                        grade: formData.grade,
                        state: formData.state,
                        schoolName: capitalize(formData.schoolName),
                        publicClassName: formData.publicName,
                    },
                );
                commit('addOrUpdateTeam', response);
            } else {
                errorMessage = error?.message?.message;
            }

            return {
                success,
                errorMessage,
            };
        },
    },
};
