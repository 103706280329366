/* global mixpanel */
import { router } from '@/main';

export const CLASS_LISTS_MP_EVENTS = {
    VIEWED_CLASS_CREATION_PAGE: 'viewedClassCreationPage',
    VIEWED_CLASS_CREATION_MODAL: 'viewedClassCreationModal',
    CREATED_CLASS: 'createdClass',
    CHANGED_CLASS: 'changedClass',
    DELETED_CLASS: 'deletedClass',
    ATTACHED_CLASS_TO_GAME: 'attachedClassToGame',
    OPENED_SOLO_TRACK_FROM_REPORTS: 'openedSoloTrackFromReports',
    VIEWED_CLASS_PAGE: 'viewedClassPage',
    VIEWED_CLASS_PAGE_NO_CLASSES: 'viewedClassPageNoClasses',
    ADDED_STUDENTS_TO_CLASS: 'addedStudents',
    CLICKED_EDIT_STUDENT_IN_CLASS: 'clickedOnEditStudent',
    CLICKED_VIEW_PARENT_LETTER: 'clickedViewParentLetter',
    CLICKED_VIEW_PARENT_LETTER_PREVIEW: 'clickedViewParentLetterPreview',
    CLICKED_IMPORT_FROM_GOOGLE_BTN: 'clickedImportFromGoogle',
    SEES_GOOGLE_CLASSROOM_CLASSES: 'seesGoogleClassroomClasses',
    CLICKED_CHOOSE_CLASSES_TO_IMPORT_FROM_GC: 'clickedChooseClassesToImport',
    CLICKED_CHOOSE_GRADES_FOR_CLASSES_TO_IMPORT_FROM_GC:
        'clickedChooseGradesForClassesToImport',
    IMPORTED_CLASSES_FROM_GC: 'importedClassesFromGoogleClassroom',
    CLICKED_IMPORT_FROM_CLEVER_BTN: 'clickedImportFromClever',
    SEES_CLEVER_CLASSROOM_CLASSES: 'seesCleverClassroomClasses',
    CLICKED_CHOOSE_CLASSES_TO_IMPORT_FROM_CC:
        'clickedChooseClassesToImportFromClever',
    CLICKED_CHOOSE_GRADES_FOR_CLASSES_TO_IMPORT_FROM_CC:
        'clickedChooseGradesForClassesToImportFromClever',
    IMPORTED_CLASSES_FROM_CC: 'importedClassesFromCleverClassroom',
    VIEWED_STUDENT_ACCESS_INFO_PAGE: 'viewedStudAccessInfoPage',
    CLICKED_ADD_STUDENTS_BTN: 'clickedAddStudentsButton',
    COPIED_INVITE_PARENTS_LINK: 'copiedInviteParentsLink',
    INVITED_PARENTS_TO_CREATE_ACCOUNTS: 'invitedParentsToCreateAccounts',
    OPENED_CLASS_FEED: 'openedClassFeed',
    CLICKED_CLAIM_STARS: 'clickedClaimStars',
    REQUESTED_CLASS_IMPORT: 'requestedClassImport',
};

export const CLASS_LISTS_MP_METHODS = {
    viewedClassCreationPage() {
        mixpanel.track('Viewed class creation page');
    },
    viewedClassCreationModal() {
        mixpanel.track('Sees class creation modal');
    },
    createdClass({ className, grade }) {
        mixpanel.people.increment('Nr of classes created');

        let createdFrom = 'Other';
        const currentRouteName = router.currentRoute.name;

        if (currentRouteName === 'host.results') {
            createdFrom = 'Podium view';
        } else if (currentRouteName === 'teacher.create.class') {
            createdFrom = 'Create class view';
        } else if (currentRouteName === 'host.lobby') {
            createdFrom = 'Pre-game';
        }

        mixpanel.track('Created a class', {
            'Class name': className,
            'Class Grade': grade,
            Location: createdFrom,
        });
    },
    changedClass({ classCode }) {
        mixpanel.track('Changed class info', {
            'Class code': classCode,
        });
    },
    deletedClass({ classCode, numberOfClassesLeft }) {
        mixpanel.track('Deleted a class', {
            'Class code': classCode,
        });
        mixpanel.people.set({
            'Nr of classes created': numberOfClassesLeft,
        });
    },
    attachedClassToGame({ gameCode, className }) {
        // let location = 'Opened report';
        // const currentRouteName = router.currentRoute.name;
        //
        // if (currentRouteName === 'host.reports') {
        //     location = 'Reports list';
        // }
        // mixpanel.track('Attached a class to report', {
        //     'Game code': gameCode,
        //     'Class name': className,
        //     Location: location,
        // });
    },
    openedSoloTrackFromReports() {
        mixpanel.track('Clicked Assign ST in Game Report');
    },
    viewedClassPage({
        className,
        classCode,
        numberOfStudents,
        grade,
        studentsWithParentAccountsCount,
    }) {
        mixpanel.track('Viewed class overview page', {
            'Class name': className,
            'Class code': classCode,
            'Number of students': numberOfStudents,
            'Number of linked parents': studentsWithParentAccountsCount,
            'Class Grade': grade,
        });
    },
    viewedClassPageNoClasses() {
        mixpanel.track('Viewed class overview page - no classes');
    },
    addedStudents({ classCode, numberOfStudents }) {
        mixpanel.track('Added students to a class', {
            'Class code': classCode,
            'Number of students': numberOfStudents,
        });
    },
    clickedOnEditStudent({ classCode }) {
        mixpanel.track('Clicked on Edit student', {
            'Class code': classCode,
        });
    },
    clickedViewParentLetter({ classCode }) {
        mixpanel.track('Clicked on Invite parents', {
            'Class code': classCode,
        });
    },
    clickedViewParentLetterPreview({ classCode }) {
        mixpanel.track('Opened preview of Parent letters', {
            'Class code': classCode,
        });
    },
    clickedImportFromGoogle() {
        mixpanel.track('Clicked Import from Google Classroom');
    },
    seesGoogleClassroomClasses({ nrOfAllClassesAvailableInGc }) {
        mixpanel.track('Sees import classes from GC modal w/ all classes', {
            'Number of all available classes': nrOfAllClassesAvailableInGc,
        });
    },
    clickedChooseClassesToImport({ nrOfClassesToImport }) {
        mixpanel.track('Chose classes to import from Google Classroom', {
            'Nr of classes to import': nrOfClassesToImport,
        });
    },
    clickedChooseGradesForClassesToImport({ nrOfClassesToImport }) {
        mixpanel.track('Chose grades for the classes to import', {
            'Nr of classes to import': nrOfClassesToImport,
        });
    },
    importedClassesFromGoogleClassroom({
        numberOfClasses,
        nrOfClassesToImport,
        nrOfStudents,
    }) {
        mixpanel.people.set({
            'Nr of classes created': numberOfClasses,
        });
        mixpanel.track('Imported classes from GC', {
            'Nr of classes to import': nrOfClassesToImport,
            'Total imported student count': nrOfStudents,
        });
    },
    clickedImportFromClever() {
        mixpanel.track('Clicked Import from Clever Classroom');
    },
    seesCleverClassroomClasses({ nrOfAllClassesAvailableInCc }) {
        mixpanel.track('Sees import classes from CC modal w/ all classes', {
            'Number of all available classes': nrOfAllClassesAvailableInCc,
        });
    },
    clickedChooseClassesToImportFromClever({ nrOfClassesToImport }) {
        mixpanel.track('Chose classes to import from Clever Classroom', {
            'Nr of classes to import': nrOfClassesToImport,
        });
    },
    clickedChooseGradesForClassesToImportFromClever({ nrOfClassesToImport }) {
        mixpanel.track('Chose grades for the classes to import from Clever', {
            'Nr of classes to import': nrOfClassesToImport,
        });
    },
    importedClassesFromCleverClassroom({
        numberOfClasses,
        nrOfClassesToImport,
        nrOfStudents,
    }) {
        mixpanel.people.set({
            'Nr of classes created from Clever': numberOfClasses,
        });
        mixpanel.track('Imported classes from CC', {
            'Nr of classes to import': nrOfClassesToImport,
            'Total imported student count': nrOfStudents,
        });
    },
    viewedStudAccessInfoPage({ classCode }) {
        mixpanel.track('Viewed Student Access Info page', {
            'Class code': classCode,
        });
    },
    clickedAddStudentsButton({ classCode }) {
        mixpanel.track('Clicked Add Students button', {
            'Class code': classCode,
        });
    },
    copiedInviteParentsLink({ classCode }) {
        mixpanel.track('Copied invite parents share link', {
            'Class code': classCode,
        });
    },
    invitedParentsToCreateAccounts({ classCode, emailsSent }) {
        mixpanel.track('Sent invite letters to parents', {
            'Class code': classCode,
            'Emails addresses': emailsSent,
        });
    },
    openedClassFeed({ classCode }) {
        mixpanel.track('Opened class feed', {
            'Class code': classCode,
        });
    },
    clickedClaimStars() {
        mixpanel.track('Clicked Create class to claim stars');
    },
    requestedClassImport(requestedClassImport) {
        mixpanel.track('Requested Class Import', {
            Request: requestedClassImport,
        });
    },
};
