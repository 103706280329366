/* global mixpanel */

import { getFormattedLocalTime } from '@/core/helpers/utils';
import {
    HOME_PLAY_EVENTS,
    HOME_PLAY_METHODS,
} from '@/core/services/MixPanelService/events/HomePlayEvents.js';
import {
    TEACHER_ONBOARDING_REGISTER_MP_EVENTS,
    TEACHER_ONBOARDING_REGISTER_MP_METHODS,
} from '@/core/services/MixPanelService/events/TeacherOnboardingRegister';
import {
    STUDENT_JOIN_GAME_EVENTS,
    STUDENT_JOIN_GAME_METHODS,
} from '@/core/services/MixPanelService/events/StudentJoinGame';
import {
    UNAUTHORIZED_PREMIUM_SUBSCRIPTION_EVENTS,
    UNAUTHORIZED_PREMIUM_SUBSCRIPTION_MP_METHODS,
} from '@/core/services/MixPanelService/events/UnauthorizedPremiumSubscription';
import {
    STUDENT_WITHOUT_ACCOUNT_EVENTS,
    STUDENT_WITHOUT_ACCOUNT_METHODS,
} from '@/core/services/MixPanelService/events/StudentWithoutAccount';
import {
    CERTIFICATE_PRINTOUT_EVENTS,
    CERTIFICATE_PRINTOUT_METHODS,
} from './events/TblResultCertificateEvents';
import {
    PREMIUM_METHODS,
    PREMIUM_EVENTS,
} from '@/core/services/MixPanelService/events/PremiumEvents';

export const ANONYMOUSEVENTS = {
    ...TEACHER_ONBOARDING_REGISTER_MP_EVENTS,
    ...STUDENT_JOIN_GAME_EVENTS,
    ...UNAUTHORIZED_PREMIUM_SUBSCRIPTION_EVENTS,
    ...STUDENT_WITHOUT_ACCOUNT_EVENTS,
    ...CERTIFICATE_PRINTOUT_EVENTS,
    ...PREMIUM_EVENTS,
    ...HOME_PLAY_EVENTS,
};

const eventExistsInEventList = (event) => {
    for (let eventKey in ANONYMOUSEVENTS) {
        if (ANONYMOUSEVENTS[eventKey] && ANONYMOUSEVENTS[eventKey] === event) {
            return true;
        }
    }
    return false;
};

export const AnonymousMixpanelService = (function () {
    /** Constructor */
    function AnonymousMixpanelService() {}

    AnonymousMixpanelService.prototype.track = function (
        event = '',
        args = {},
    ) {
        if (!eventExistsInEventList(event)) {
            console.error(
                `MPS: event '${event}' is not listed in ANONYMOUSEVENTS`,
            );
            return;
        }

        if (typeof privateMethods[event] !== 'function') {
            console.error(`MPS: event '${event}' is not implemented`);
            return;
        }

        let objData = {
            'Local hours': getFormattedLocalTime(),
        };

        mixpanel.register(objData);

        privateMethods[event].call(this, args);
    };

    const privateMethods = {
        ...TEACHER_ONBOARDING_REGISTER_MP_METHODS,
        ...STUDENT_JOIN_GAME_METHODS,
        ...UNAUTHORIZED_PREMIUM_SUBSCRIPTION_MP_METHODS,
        ...STUDENT_WITHOUT_ACCOUNT_METHODS,
        ...CERTIFICATE_PRINTOUT_METHODS,
        ...PREMIUM_METHODS,
        ...HOME_PLAY_METHODS,
    };

    return AnonymousMixpanelService;
})();
