import { FLAGS } from '@/core/flags.js';
import CONSTANTS from '@/core/helpers/constants';
import {
    inProductionEnv,
    jclone,
    objectStringify,
    waitSec,
} from '@/core/helpers/utils';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import CrashReportsApi from '@/core/services/AdminApi';
import Api from '@/core/services/Api';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import getAvatars from '@/core/static-json/avatar/AvatarsList3d.json';
import getBackgrounds from '@/core/static-json/avatar/BackgroundsList.json';
import getCards from '@/core/static-json/cardsData.json';
import getSkillList from '@/core/static-json/curriculumSetV2';
import getSkillList4 from '@/core/static-json/curriculumSetV4';
import decorationsJSON from '@/core/static-json/decorationsData.json';
import getFactFluencyList from '@/core/static-json/factFluencySet.json';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import {
    getAvatarItemsReward,
    getCardReward,
    getChestReward,
    getDecorationReward,
} from '@/student/helpers.js';
import oldLevelsConfig from '@/student/static-json/levelsV2.json';
import levelsConfig from '@/student/static-json/levelsV3.json';
import getParkBackdrops from '@/student/static-json/ParkBackdrops.json';
import { isEqual } from 'lodash';
import moment from 'moment';

const AccuracyThreshold = 90;

const store = {
    namespaced: true,
    state: {
        parkDecorations: decorationsJSON,
        parkBackdrops: getParkBackdrops,
        gamesHistory: [],
        badges: {},
        accuracy: {},
        grade: 1,
        math: null,
        topic: null,
        skill: null,
        stats: null,
        result: null,
        factsList: getFactFluencyList,
        isPlayWithMomDadBtnVisible: true,
        questions: [],
        debug: {
            showClosedSkills: true,
            showFluencyMode: true,
            show1v1Mode: true,
        },
        isClaimModalOpen: false,
        showClaimedItem: false,
        canCloseClaimModal: false,
        claimedItem: null,
        activeClaimedItemIndex: 0,
        claimedItems: [],
        chestReward: null,
        isFluency: false,
        is1v1: false,
        classBuildings: {},
        classBuildingsConfig: {},
        studentBuildingsList: {},
        studentBuildingsConfig: {},
        timeCorrection: 0,
        islandsMeta: [
            { key: 'farm', name: 'FARM' },
            { key: 'kingdom', name: 'KINGDOM' },
            { key: 'pier', name: 'BEACH' },
            null,
        ],
        studentIslandsMeta: [
            { key: 'playground', number: 1, name: 'PLAYGROUND' },
            { key: 'skiResort', number: 2, name: 'SKI RESORT' },
            { key: 'amusementPark', number: 3, name: 'AMUSEMENT PARK' },
            null,
        ],
        islandToDisplay: {},
        studentIslandToDisplay: 0,
        weeklyLeaderboards: {},
        allTimeLeaderboards: {},
        lastSSEvents: [],
        starsPopup: null,
        buildingWeeklyReward: null,
        brainBreakPass: false,
    },
    mutations: {
        setBrainBreakPass(state, payload) {
            state.brainBreakPass = payload;
        },
        setIslandToDisplay(state, { classCode, index }) {
            if (!classCode) return;
            state.islandToDisplay[classCode] = index || 0;
        },
        setStudentIslandToDisplay(state, number) {
            state.studentIslandToDisplay = number || 1;
        },
        setGamesHistory: (state, games) => {
            state.gamesHistory = games || [];
        },
        chooseTopic: (state, topic) => {
            state.topic = topic;
            sessionStorage.setItem('topicSaved', JSON.stringify(topic));
        },
        hiddenPlayWithMomDadBtn: (state) =>
            (state.isPlayWithMomDadBtnVisible = false),
        changeGrade: (state, grade) => {
            console.debug('store::homegame changeGrade', grade);
            state.grade = grade;
            sessionStorage.removeItem('topicSaved');
            localStorage.removeItem('student-current-topic');
        },
        setBadge: (state, { grade, topic, badge }) => {
            if (!state.badges[grade]) {
                state.badges[grade] = {};
            }

            state.badges[grade][topic] = badge;
        },
        setAccuracy: (state, { skill, accuracy, maxOnly = false }) => {
            const key = objectStringify({
                type: skill.type,
                numberGenerator: jclone(skill.numberGenerator),
            });

            state.accuracy[key] = maxOnly
                ? Math.max(state.accuracy[key] || 0, accuracy)
                : accuracy;
        },
        restoreHistory: (
            state,
            { gamesHistory, accuracy, badges, isFluency },
        ) => {
            state.badges = badges;
            state.accuracy = accuracy;
            state.gamesHistory = gamesHistory;
            state.isFluency = isFluency;
        },
        endRound: (state) => {
            state.stats.round += 1;
            state.stats.started = null;
        },
        startRound: (state) => {
            state.stats.started = new Date();
        },
        initGame: (state, { skill, math, questions, stats = null }) => {
            state.skill = skill;
            state.math = math;
            state.questions = questions;
            state.stats = stats
                ? {
                      ...stats,
                      started: new Date(stats.started),
                  }
                : {
                      playedTime: 0,
                      started: new Date(),
                      round: 1,
                      correctAnswers: 0,
                      wrongAnswers: 0,
                      answers: [],
                  };
            state.result = null;
        },
        setGameStats: (state, stats) => {
            console.log('store::homegame::setGameStats', stats);

            state.stats = stats;
        },
        finishGame: (state) => {
            if (!state.stats) return;

            state.stats.playedTime = Math.floor(
                (new Date().getTime() -
                    new Date(state.stats.started).getTime()) /
                    1000,
            );
        },
        setGameResult: (state, next) => {
            state.result = next;
        },
        resetStateToDefault: (state) => {
            state.gamesHistory = [];
            state.badges = {};
            state.accuracy = {};
            state.grade = 1;
            state.math = null;
            state.topic = null;
            state.skill = null;
            state.stats = null;
            state.result = null;
            state.factsList = getFactFluencyList;
            state.isPlayWithMomDadBtnVisible = true;
            state.questions = [];
            state.isClaimModalOpen = false;
            state.showClaimedItem = false;
            state.canCloseClaimModal = false;
            state.claimedItem = null;
            state.activeClaimedItemIndex = 0;
            state.claimedItems = [];
            state.chestReward = null;
            state.classBuildings = {};
            state.studentBuildingsList = {};
            state.islandToDisplay = {};
            state.studentIslandToDisplay = 0;
            state.weeklyLeaderboards = {};
            state.weeklyTop5Leaderboards = {};
            state.allTimeLeaderboards = {};
        },
        setDebug: (state, { name, value }) => {
            state.debug[name] = value;
        },
        setClaimedItem: (state, { index, ...next }) => {
            state.claimedItems[index || 0] = next;
        },
        clearClaimedItems: (state) => {
            state.claimedItems = [];
            state.activeClaimedItemIndex = 0;
        },
        setChestReward: (state, next) => {
            state.chestReward = next;
        },
        setShowClaimedItem: (state, next) => {
            state.showClaimedItem = next;
        },
        setActiveClaimedItem: (state, next) => {
            state.activeClaimedItemIndex = next;
        },
        setClaimModalOpen: (state, next) => {
            state.isClaimModalOpen = next;
        },
        setCanCloseClaimModal: (state, next) => {
            state.canCloseClaimModal = next;
        },
        setIsFluency: (state, next) => {
            state.isFluency = next;
        },
        setIs1v1: (state, next) => {
            state.is1v1 = next;
        },
        setClassBuildingsConfig: (state, next) => {
            if (!next.classCode) return;
            state.classBuildingsConfig[next.classCode] = next;
        },
        setClassBuildings: (state, next) => {
            if (!next._id) return;
            state.classBuildings[next._id] = next;
        },
        setStudentBuildingsConfig: (state, next) => {
            state.studentBuildingsConfig = next;
        },
        setStudentBuildings: (state, next) => {
            state.studentBuildingsList = next;
        },
        setTimeCorrection: (state, next) => {
            state.timeCorrection = next;
        },
        setWeeklyLeaderboard: (state, next) => {
            state.weeklyLeaderboards[next.classCode] = next.data;
        },
        setAllTimeLeaderboard: (state, next) => {
            state.allTimeLeaderboards[next.classCode] = next.data;
        },
        setSSEvent: (state, data) =>
            state.lastSSEvents.push({ ...data, time: new Date() }),
        clearSSEvents: (state) => (state.lastSSEvents = []),
        setStarsPopup: (state, data) => {
            state.starsPopup = data;
        },
        setBuildingWeeklyReward: (state, next) => {
            state.buildingWeeklyReward = next;
        },
    },
    getters: {
        brainBreakPass: (state) => {
            return state.brainBreakPass;
        },
        userBuildingOpens:
            (state, getters, rootState, rootGetters) => (classCode) => {
                return (
                    rootGetters.user?.studentInfo?.buildings?.[classCode] || {}
                );
            },
        userLastStage: (state, getters) => (classCode) => {
            const islandKey =
                state.islandsMeta?.[state.islandToDisplay[classCode]]?.key ||
                'farm';

            return (
                getters.userBuildingOpens(classCode)?.[islandKey]?.stage || 0
            );
        },
        userIsland: (state, getters) => (classCode) => {
            const opens = getters.userBuildingOpens(classCode);
            const key = Object.keys(opens)?.pop() || 'farm';
            let index = state.islandsMeta.findIndex((el) => el.key === key);

            if (opens?.[key]?.stage === 5) index++;
            if (index >= state.islandsMeta.length) index--;

            const stage = opens?.[state.islandsMeta?.[index]?.key]?.stage || 0;

            return { index, stage };
        },
        mBucksBalance: (state, getters, rootState, rootGetters) =>
            rootGetters.user?.studentInfo?.battlepass?.gems || 0,
        math: (state) => state.math,
        parkBackdrops: (state) => {
            return state.parkBackdrops;
        },
        parkDecorations: (state) => {
            return state.parkDecorations;
        },
        stats: (state) => {
            return state.stats;
        },
        topic: (state) => {
            return state.topic;
        },
        topicsList: (state) => {
            const skillList = getSkillList4.find(
                (item) => item.grade === state.grade,
            );

            return skillList.skills.map((item) => {
                return {
                    name: item.category,
                    icon: item.categoryIcon ? item.categoryIcon : false,
                    iconLabel: item.categoryIconLabel
                        ? item.categoryIconLabel
                        : '',
                    skillCount: item.skills.length,
                    skills: item.skills,
                };
            });
        },
        sortedFactsList: (state) => {
            return state.factsList.map((topic) => {
                const lowerSkills =
                    topic.topics[0].skills.length >
                    topic.topics[1].skills.length
                        ? topic.topics[0].skills
                        : topic.topics[1].skills;

                const higherSkills =
                    topic.topics[0].skills.length <
                    topic.topics[1].skills.length
                        ? topic.topics[0].skills
                        : topic.topics[1].skills;

                const skills = [...lowerSkills, ...higherSkills];

                return {
                    name: topic.category,
                    icon: topic.categoryIcon ?? false,
                    iconLabel:
                        topic.category !== 'Subtraction'
                            ? topic.category.toLowerCase()
                            : 'substraction',
                    skillCount: skills.length,
                    skills,
                    isFactsList: true,
                };
            });
        },
        gradeTotalGreenBadges: (state) => (grade) => {
            return Object.values(state.badges[grade] || {}).reduce(
                (acc, val) => acc + val,
                0,
            );
        },
        gamesPassedCount: (state) => {
            return state.gamesHistory.filter((el) => el.accuracy >= 80).length;
        },
        gamesHistory: (state) => {
            return state.gamesHistory;
        },
        gameAccuracy: (state) => {
            if (!state.stats?.answers?.length) return;

            return (
                Math.round(
                    (state.stats?.correctAnswers /
                        state.stats?.answers.length) *
                        100,
                ) || 0
            );
        },
        gameFluency: (state) => {
            if (!state.stats?.answers?.length) return;

            const totalAnswersCount = Math.max(
                state.stats.correctAnswers + state.stats.wrongAnswers,
                1,
            );

            return (
                Math.round(state.stats.playedTime / totalAnswersCount) * 60 || 0
            );
        },
        skill: (state) => {
            return state.skill;
        },
        getAllAvatars: () => {
            return getAvatars.flatMap((group) => group.avatars);
        },
        getAllCards: () => {
            return getCards.flatMap((group) => group.items);
        },
        getMyAvatar(_, getters, rootState, rootGetters) {
            const avatars = getAvatars[0].avatars.map((avatar) => avatar.id);

            return (
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial
                    ?.avatar ||
                avatars[Math.floor(Math.random() * avatars.length)] // @todo: use seed to generate random numbers
            );
        },
        getRandomAvatar() {
            const avatars = getAvatars[0].avatars.map((avatar) => avatar.id);

            return avatars[Math.floor(Math.random() * avatars.length)];
        },
        _getXp(state, getters, rootState, rootGetters) {
            return rootGetters.user?.studentInfo?.counters?.mathPassTasksSolved;
        },
        getXpForOldLevelFromNewConfig(_, getters) {
            const currentLevel = getters.getMyCurrentLevelFromOldConfig;
            return levelsConfig[`level${currentLevel}`];
        },
        getMyCurrentLevelFromOldConfig(_, getters) {
            const xp = getters._getXp;

            for (let i = Object.keys(oldLevelsConfig).length; i >= 0; i--) {
                if (xp >= oldLevelsConfig[`level${i}`]) {
                    return i;
                }
            }
        },
        getMyCurrentLevel(_, getters) {
            const xp = getters._getXp;

            for (let i = Object.keys(levelsConfig).length; i >= 0; i--) {
                if (xp >= levelsConfig[`level${i}`]) {
                    return i;
                }
            }
        },
        getNextLevelXp(_, getters) {
            const xp = getters._getXp;

            for (let i = Object.keys(levelsConfig).length; i >= 0; i--) {
                if (xp >= levelsConfig[`level${i}`]) {
                    if (i === Object.keys(levelsConfig).length) return Infinity;
                    return levelsConfig[`level${i + 1}`];
                }
            }
        },
        getCurrentLevelXp(_, getters) {
            const xp = getters._getXp;

            for (let i = Object.keys(levelsConfig).length; i >= 0; i--) {
                if (xp >= levelsConfig[`level${i}`]) {
                    if (i === Object.keys(levelsConfig).length) return Infinity;

                    return levelsConfig[`level${i}`];
                }
            }
        },
        getMyCustomAvatar(_, getters, rootState, rootGetters) {
            return (
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial
                    ?.customAvatar || null
            );
        },
        getMyBackground(_, getters, rootState, rootGetters) {
            return (
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial
                    ?.background || null
            );
        },
        getCurrentEnergy(state, getters, rootState, rootGetters) {
            return rootGetters.user?.studentInfo?.energy?.current || 0;
        },
        getMyFrame(_, getters, rootState, rootGetters) {
            return (
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial
                    ?.frame || 'no-frame'
            );
        },
        getMyPet(_, getters, rootState, rootGetters) {
            return (
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial
                    ?.pet || null
            );
        },
        getGameResult: (state) => {
            return state.result;
        },
        getSkillAccuracy(state) {
            return (skill) => {
                const key = objectStringify({
                    type: skill.type,
                    numberGenerator: jclone(skill.numberGenerator),
                });

                if (!state.accuracy[key]) return 0;

                return state.accuracy[key] || 0;
            };
        },
        isFluencyGame(state) {
            if (state.debug.showFluencyMode) {
                return state.isFluency;
            } else {
                return false;
            }
        },
        currentSkillAccuracy: () =>
            parseInt(sessionStorage.getItem('HG-currentSkillAccuracy'), 10),
        debug(state) {
            return state.debug;
        },
        claimedItem: (state) =>
            state.claimedItems[state.activeClaimedItemIndex],
        schoolTime(state, getters, rootState, rootGetters) {
            return rootGetters.isSchoolTime;
        },
        /**
         * Subset of skills for current grade
         */
        currentGradeSkillList: (state) => () => {
            return (
                getSkillList4.find((item) => item.grade === state.grade)
                    ?.skills || []
            );
        },
        /**
         * Subset of skills for current grade and category
         */
        currentSkillList(state, getters) {
            return (
                getters
                    .currentGradeSkillList()
                    .find((item) => item.category === state.topic)?.skills || []
            );
        },
        currentSkillIndex(state, getters) {
            const currentSkill = getters.currentSkillList.find(
                (item) => item?.name === state?.skill?.name,
            );

            return getters.currentSkillList.indexOf(currentSkill) + 1;
        },
        formattedSkills(state, getters) {
            return getters.currentSkillList.map((skill, index) => {
                const data = getters.gamesHistory?.find(
                    (item) => item.topic === skill.name,
                );

                const fluencyLevel = getters.calculateFluencyLevel(
                    data?.fluency,
                );
                const accuracyLevel = getters.calculateAccuracyLevel(
                    data?.accuracy,
                );
                return {
                    ...data,
                    skillData: skill,
                    level: index + 1,
                    fluencyLevel,
                    accuracyLevel,
                    stars: fluencyLevel + accuracyLevel,
                };
            });
        },
        formattedSkillsWithBattleNodes(state, getters) {
            return getters.formattedSkillRowsWithBattleNodes.flat();
        },
        /**
         * Uses the skills list to generate the rows and map each nodes data in one go.
         * This was done here to reduce computation on FE and generate the array of rows.
         */
        formattedSkillRowsWithBattleNodes(state, getters) {
            const skills = getters.currentSkillList;
            const itemsPerRow = 3;
            const rows = [];
            let currentLevel = 1;
            for (let i = 0; i < skills.length; i += itemsPerRow) {
                const skillRow = skills
                    .slice(i, i + itemsPerRow)
                    .map((skill) => {
                        const data = getters.gamesHistory?.find(
                            (item) => item.topic === skill.name,
                        );
                        const accuracyLevel = getters.calculateAccuracyLevel(
                            data?.accuracy,
                        );
                        const fluencyLevel = getters.calculateFluencyLevel(
                            data?.fluency,
                        );
                        return {
                            ...data,
                            skillData: skill,
                            level: currentLevel++,
                            fluencyLevel,
                            accuracyLevel,
                            stars: accuracyLevel + fluencyLevel,
                        };
                    });
                // add battle node
                const lastAccuracyNode = skillRow[skillRow.length - 1];
                const rawdata = getters.gamesHistory?.filter(
                    (item) =>
                        lastAccuracyNode.topic === item.topic &&
                        item.nodeLevel === currentLevel &&
                        item.gameMode === '1v1',
                );
                const data = rawdata.reduce((acc, val) => {
                    if (!acc.battleStars) {
                        acc = val;
                    }
                    if (acc.battleStars < val.battleStars) {
                        acc.battleStars = val.battleStars;
                    }
                    return acc;
                }, {});
                skillRow.push({
                    ...data,
                    skillChoices: jclone(skillRow),
                    level: currentLevel++,
                    is1v1: true,
                });
                rows.push(skillRow);
            }
            return rows.filter((item) => item.length);
        },
        /**
         * Uses the skills list to generate the rows and map each nodes data in one go.
         * This was done here to reduce computation on FE and generate the array of rows.
         */
        formattedSkillRows(state, getters) {
            const skills = getters.currentSkillList;
            const itemsPerRow = 4;
            const rows = [];
            let currentLevel = 1;
            for (let i = 0; i < skills.length; i += itemsPerRow) {
                const skillRow = skills
                    .slice(i, i + itemsPerRow)
                    .map((skill) => {
                        const data = getters.gamesHistory?.find(
                            (item) => item.topic === skill.name,
                        );
                        const accuracyLevel = getters.calculateAccuracyLevel(
                            data?.accuracy,
                        );
                        const fluencyLevel = getters.calculateFluencyLevel(
                            data?.fluency,
                        );
                        return {
                            ...data,
                            skillData: skill,
                            level: currentLevel++,
                            fluencyLevel,
                            accuracyLevel,
                            stars: accuracyLevel + fluencyLevel,
                        };
                    });
                rows.push(skillRow);
            }
            return rows.filter((item) => item.length);
        },
        currentAccuracySkill(state, getters) {
            if (store.state.is1v1) {
                return getters.formattedSkillsWithBattleNodes.filter(
                    (s) => s.accuracyLevel < 2 && !s.is1v1,
                )[0];
            }
            return getters.formattedSkills.filter(
                (s) => s.accuracyLevel < 2,
            )[0];
        },
        currentFluencySkill(state, getters) {
            if (store.state.is1v1) {
                return getters.formattedSkillsWithBattleNodes.filter(
                    (s) => s.fluencyLevel < 2 && !s.is1v1,
                )[0];
            }
            return getters.formattedSkills.filter((s) => s.fluencyLevel < 2)[0];
        },
        calculateAccuracyLevel() {
            return (accuracy) => {
                if (accuracy >= 90) return 3;
                if (accuracy >= 80) return 2;
                if (accuracy >= 60) return 1;
                return 0;
            };
        },
        calculateFluencyLevel() {
            return (fluency) => {
                if (fluency) {
                    const tpmList = {
                        level1: 3,
                        level2: 10,
                        level3: 20,
                    };

                    if (fluency < tpmList.level1) {
                        return 0;
                    }

                    if (fluency < tpmList.level2) {
                        return 1;
                    }

                    if (fluency >= tpmList.level2 && fluency < tpmList.level3) {
                        return 2;
                    }

                    if (fluency >= tpmList.level3) {
                        return 3;
                    }

                    return 0;
                } else {
                    return 0;
                }
            };
        },
        currentIslandKey(state, getters, rootState, rootGetters) {
            const classCode = rootGetters['v2/user/getClassCode'];

            return (
                state.islandsMeta?.[state.islandToDisplay[classCode]]?.key ||
                'farm'
            );
        },
        currentStudentIslandKey(state) {
            const islandMeta = state.studentIslandsMeta.find(
                (island) => island?.number === state.studentIslandToDisplay,
            );

            return islandMeta?.key || 'playground';
        },
        avatarData(state, getters) {
            return {
                avatar: getters.getMyAvatar,
                customAvatar: getters.getMyCustomAvatar,
                background: getters.getMyBackground,
                frame: getters.getMyFrame,
            };
        },
        fluencyGameDuration() {
            return 30;
        },
    },
    actions: {
        saveCache: (store) => {
            if (store.state.is1v1) {
                store.dispatch('v2/onevone/saveCache', null, { root: true });
            }
            const cache = {
                grade: store.state.grade,
                topic: store.state.topic,
                skill: store.state.skill,
                stats: store.state.stats,
                questions: store.state.questions,
                gamesHistory: store.state.gamesHistory,
                badges: store.state.badges,
                accuracy: store.state.accuracy,
                result: store.state.result,
                isFluency: store.state.isFluency,
                is1v1: store.state.is1v1,
            };

            sessionStorage.setItem('cache/home-v10', JSON.stringify(cache));
        },
        loadCache: async (store) => {
            const jcache = sessionStorage.getItem('cache/home-v10');

            if (!jcache) return;

            let cache;

            try {
                cache = JSON.parse(jcache);
            } catch (err) {
                cache = null;

                console.error('store::homegame loading cache error', err);
            }

            if (!cache) return;

            console.log(cache, 'qq');

            store.commit('restoreHistory', {
                gamesHistory: cache.topic,
                accuracy: cache.accuracy,
                badges: cache.badges,
                isFluency: cache.isFluency,
            });

            store.commit('changeGrade', cache.grade);
            store.commit('chooseTopic', cache.topic);
            let math;
            if (cache.is1v1) {
                math = cache.skill.skillChoices.map((_skill) =>
                    TopicsFactory.getTopicObject(jclone(_skill)),
                );
                store.dispatch('v2/onevone/loadCache', null, { root: true });
            } else {
                math = TopicsFactory.getTopicObject(cache.skill);
            }

            store.commit('initGame', {
                skill: cache.skill,
                math,
                questions: cache.questions,
                stats: cache.stats,
            });
            store.commit('setIs1v1', cache.is1v1);

            store.commit('setGameResult', cache.result);
        },
        clearCache: () => {
            sessionStorage.removeItem('cache/home-v10');
        },
        resetState: (store, gameOnly = false) => {
            if (!gameOnly) {
                store.commit('restoreHistory', {
                    gamesHistory: [],
                    accuracy: {},
                    badges: {},
                });

                store.commit('changeGrade', 1);

                store.commit('chooseTopic', null);
            }

            store.commit('initGame', {
                skill: null,
                math: null,
                questions: [],
                stats: null,
            });

            store.commit('setGameResult', null);

            store.dispatch('clearCache');

            if (gameOnly) {
                store.dispatch('saveCache');
            }
        },
        resetStateToDefault: (store) => {
            store.commit('resetStateToDefault');
        },
        checkGradeRestore: async (store) => {
            const user = store.rootGetters.user;

            const gradeSaved =
                store.rootGetters['v2/user/activeClass']?.grade ||
                user?.studentInfo?.gradeInfo?.grade ||
                1;

            let activeGrade = 1;

            if (gradeSaved) {
                if (gradeSaved === 'K') {
                    activeGrade = 1;
                } else if (
                    gradeSaved === 'High school' ||
                    gradeSaved === 'I am an adult' ||
                    gradeSaved === 'Adult'
                ) {
                    activeGrade = 6;
                } else if (Number(gradeSaved) && Number(gradeSaved) > 6) {
                    activeGrade = 6;
                } else {
                    activeGrade = Number(gradeSaved);
                }
            }

            if (store.state.grade !== activeGrade) {
                store.commit('changeGrade', activeGrade);
            }
        },
        checkTopicRestore: async (store) => {
            try {
                const storedTopic = sessionStorage.getItem('topicSaved');
                const activeTopic = storedTopic
                    ? JSON.parse(storedTopic)
                    : null;

                if (!activeTopic) return;
                if (store.state.topic) return;

                store.commit('chooseTopic', activeTopic);
            } catch (error) {
                console.error(error.message);
            }
        },
        checkGameRestore: (store) => {
            if (store.state.stats && store.state.questions.length) {
                return true;
            }

            store.dispatch('loadCache');

            return store.state.stats && store.state.questions.length;
        },
        checkGamesHistory: async (store) => {
            if (store.state.gamesHistory?.length) return;

            await store.dispatch('loadGamesHistory');
        },
        loadGamesHistory: async (store) => {
            let games = [];

            try {
                const response = await Api().get(
                    'home-game-v10SimpleTreeSocial/player/topics',
                );

                if (response.data.success) {
                    games = response.data.data || [];
                }
            } catch (e) {
                console.error(e);
            }

            if (!games || !games.length) return;

            store.commit('setGamesHistory', games);

            getSkillList.forEach((skillList) => {
                skillList.categories.forEach((category) => {
                    const topics = category.topics
                        .map((topic) => topic.skills)
                        .flat();

                    const badge = games.reduce((acc, game) => {
                        const one =
                            store.state.debug.showFluencyMode &&
                            game.accuracy >= AccuracyThreshold &&
                            topics.some((skill) =>
                                isEqual(
                                    game.numberGenerator,
                                    skill.numberGenerator,
                                ),
                            )
                                ? 1
                                : 0;

                        return acc + one;
                    }, 0);

                    store.commit('setBadge', {
                        grade: skillList.grade,
                        topic: category.category,
                        badge,
                    });

                    topics.forEach((topic) => {
                        const accuracy = games.reduce((acc, game) => {
                            if (
                                game.gameType !== topic.type ||
                                !isEqual(
                                    game.numberGenerator,
                                    topic.numberGenerator,
                                )
                            ) {
                                return acc;
                            }

                            return Math.max(acc, game.accuracy);
                        }, 0);

                        // Save to store only values > 0
                        if (accuracy > 0) {
                            store.commit('setAccuracy', {
                                skill: topic,
                                accuracy,
                                maxOnly: true,
                            });
                        }
                    });
                });
            });
        },
        startSkillGame: async (
            store,
            {
                skill,
                isFluency = false,
                is1v1 = false,
                isPlayAgain = false,
                nodeLevel = null,
            },
        ) => {
            if (
                !store.rootGetters.isBefore3PM &&
                store.getters.getCurrentEnergy <= 0
            ) {
                return false;
            }
            const topic = store.state.topic;
            let math;
            if (!is1v1) {
                math = TopicsFactory.getTopicObject(jclone(skill));
            }
            const currentGrade = store.state.grade;

            if (!math && !is1v1) {
                return false;
            }

            store.commit('setIsFluency', isFluency);
            store.commit('setIs1v1', is1v1);

            let questionsCount = 7;

            if (isFluency) {
                // generate more questions for fluency game
                questionsCount = 100;
            }
            let questions;
            if (is1v1) {
                const result = await store.dispatch(
                    'v2/onevone/prepareGame',
                    { isPlayAgain, skill, topic, nodeLevel },
                    { root: true },
                );
                math = result.math;
                questions = result.questions;
            } else {
                questions = math.generateQuestions(questionsCount);
            }

            console.log(
                'store::homegame::startSkillGame',
                skill,
                math,
                topic,
                questions,
            );

            const name = topic?.name || skill.name || topic;

            store.commit('initGame', {
                skill: {
                    ...skill,
                    topic: name,
                    nodeLevel,
                },
                math,
                questions,
            });

            // just to see a bit of loading animation :)
            await waitSec(0.1);

            const user = store.rootGetters.user;

            const currentIsland = sessionStorage.getItem('currentIsland');

            if (is1v1) {
                new TrackingService(user).track(EVENTS.CLICKED_START_1V1, {
                    level: nodeLevel,
                });
            } else {
                new TrackingService(user).track(EVENTS.SPG_STARTED_PLAYING, {
                    taskType: skill.type,
                    taskScale: name,
                    creatorRole: user.role,
                    island:
                        currentIsland === 'personal'
                            ? 'Personal Island'
                            : 'Class World',
                });
            }

            if (inProductionEnv()) {
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/soloGame/startedGames`,
                );
            }

            sessionStorage.setItem(
                'isHomeGameV10SimpleTreeSocial-currect-game',
                JSON.stringify({
                    topic,
                    skill,
                }),
            );

            sessionStorage.setItem(
                'homeGameV10SimpleTreeSocial-last-played-skill',
                JSON.stringify({ skill, currentGrade }),
            );

            // Save current xp to sessinStorage
            sessionStorage.setItem(
                'homeGameV10SimpleTreeSocial-last-xp',
                Math.round(user.studentInfo?.homeGameV10SimpleTreeSocial?.xp) ||
                    0,
            );

            store.dispatch('saveCache');

            return true;
        },
        gameAnswer: (store, stats) => {
            store.commit('setGameStats', stats);

            store.dispatch('saveCache');

            if (
                !store.state.is1v1 &&
                !store.rootGetters.isBefore3PM &&
                store.state.stats.answers.length === 1
            ) {
                // 1st answer - reduce energy
                store.dispatch('consumeEnergy');
            }

            return stats.answers[stats.answers.length - 1].correct;
        },
        endGame: async (store, stats) => {
            store.commit('setGameStats', stats);
            store.commit('finishGame');

            const data = {
                skill: jclone(store.state.skill),
                // only accuracy stats are used by BE for home games
                // so there is no need to send more data than used
                stats: {
                    playedTime: store.getters.isFluencyGame
                        ? store.getters.fluencyGameDuration
                        : stats.playedTime,
                    correctAnswers: store.state.stats.correctAnswers,
                    wrongAnswers: store.state.stats.wrongAnswers,
                },
                gameMode: store.state.is1v1
                    ? CONSTANTS.ONE_V_ONE_GAME_MODE
                    : store.getters.isFluencyGame
                      ? CONSTANTS.FLUENCY_GAME_MODE
                      : CONSTANTS.SOLO_GAME_MODE,
                winState: store.state.is1v1
                    ? store.rootGetters['v2/onevone/winState']
                    : null,
                nodeLevel: store.getters.skill?.nodeLevel,
            };
            console.log('HOME GAME END', data);
            let result = null;
            try {
                const response = await Api().post(
                    'home-game-v10SimpleTreeSocial/endgame',
                    data,
                );
                if (response.data?.success) {
                    result = response.data?.data?.endGameStats;
                    store.dispatch('v2/user/update', response.data?.data, {
                        root: true,
                    });
                    sessionStorage.removeItem('studentFirstTimeSession');
                }
            } catch (e) {
                console.error(e);
                return false;
            }

            const user = store.rootGetters.user;

            const currentIsland = sessionStorage.getItem('currentIsland');

            new TrackingService(user).track(EVENTS.AT_GAME_FINISHED, {
                problemsSolved: data.stats.correctAnswers,
                island:
                    currentIsland === 'personal'
                        ? 'Personal Island'
                        : 'Class World',
            });

            sessionStorage.setItem('soloGameEnded', JSON.stringify(result));

            if (
                window.location.hostname !== 'localhost' &&
                window.location.hostname !== 'qa.99math.com' &&
                window.location.hostname !== 'test.99math.com'
            ) {
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/soloGame/finishedGames`,
                );
            }

            store.commit('setGameResult', result);
            store.dispatch('saveCache');
            void store.dispatch('loadGamesHistory');
            return !!result;
        },

        async refillEnergy(store) {
            const response = await Api().post(
                'home-game-v10SimpleTreeSocial/energy/refill',
            );

            const { success, data } = response.data;

            if (success) {
                store.dispatch(
                    'v2/user/update',
                    { studentInfo: data },
                    { root: true },
                );
            }
        },
        async consumeEnergy(store) {
            const response = await Api().post(
                'home-game-v10SimpleTreeSocial/energy/remove',
            );

            const { success, data } = response.data;

            if (success) {
                store.dispatch(
                    'v2/user/update',
                    { studentInfo: data },
                    { root: true },
                );
            }
        },
        handleSetDebugInfo(store, data) {
            store.commit('setDebug', data);
        },

        createActivityLog({ state, rootGetters }, claimedItem) {
            const user = rootGetters.user;
            const avatarData = rootGetters['v2/homegame/avatarData'];
            const playerName = rootGetters['v2/user/playerName'];

            if (['emoji-pack', 'bundle'].includes(claimedItem.type)) {
                // ignore activity of emoji packs and bundles
                return false;
            }
            let name = 'gotNewAvatar';

            let description = claimedItem.name;

            if (claimedItem.card) {
                name = 'gotNewCard';

                description = claimedItem.card.name;
            } else if (claimedItem.decoration) {
                name = 'gotNewDecoration';

                description = claimedItem.decoration.name;
            } else if (claimedItem.avatar) {
                name = 'gotNewAvatar';

                description = claimedItem.avatar.id;
            } else if (claimedItem.emoji) {
                name = 'gotNewReaction';

                description = claimedItem.emoji;
            } else if (claimedItem.frame) {
                name = 'gotNewFrame';

                description = claimedItem.frame.id;
            } else if (claimedItem.background) {
                name = 'gotNewBackground';

                description = claimedItem.background.id;
            } else if (claimedItem.type === 'frame') {
                name = 'gotNewFrame';

                description = claimedItem.id;
            } else if (claimedItem.type === 'background') {
                name = 'gotNewBackground';

                description = claimedItem.id;
            }

            Promise.all(
                Object.keys(user?.studentInfo?.classLists || {}).map(
                    (classCode) =>
                        Api().post('activity-logs/create', {
                            name,
                            classCode,
                            playerName,
                            description,
                            metaData: claimedItem.metaData,
                            ...avatarData,
                        }),
                ),
            );
        },
        async claimAvatar({ rootGetters, dispatch }, item) {
            if (!item || !item.rarity) return;

            if (item.rarity === 'common') return;

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            const avatarData = rootGetters['v2/homegame/avatarData'];
            const classCode = rootGetters['v2/user/getClassCode'];
            const playerName = rootGetters['v2/user/playerName'];
            const userId = rootGetters['v2/user/uid'];

            const data = {
                type: `unlockedAvatar${capitalizeFirstLetter(item.rarity)}`,
                receiverId: classCode,
                userInfo: {
                    playerName,
                    userId,
                    ...avatarData,
                },
                metadata: {},
            };

            await dispatch(
                'v2/sse/emitEvent',
                { channel: 'solo-track', data },
                { root: true },
            );
        },
        async openChest(store, defaultReward) {
            const userChestData = store.rootGetters['v2/user/chest'];
            const numberOfOpenedChests = userChestData?.numberOfOpenedChests;

            store.commit('clearClaimedItems');

            let countOfRewardsInChest = 1;
            let rewardIsCard = Math.random() > 0.3;

            // First 2 chests should be 2 avatars each
            if (numberOfOpenedChests < 2) {
                rewardIsCard = false;

                countOfRewardsInChest = 2;
            }

            store.commit('setActiveClaimedItem', 0);
            store.commit('setShowClaimedItem', true);
            store.commit('setCanCloseClaimModal', false);

            const user = store.rootGetters.user;

            const cardReward = getCardReward(user);

            if (!defaultReward && cardReward && rewardIsCard) {
                store.commit('setClaimedItem', { ...cardReward });

                try {
                    const result = await Api().post(`/home-game/cards/add`, {
                        name: cardReward.name,
                        amount: 1,
                    });

                    const { success, data } = result.data;

                    if (success) {
                        store.dispatch(
                            'v2/user/update',
                            { studentInfo: data },
                            { root: true },
                        );
                    }

                    await Api().post(
                        'home-game-v10SimpleTreeSocial/open-chest-main',
                    );

                    store.dispatch('createActivityLog', cardReward);

                    await new TrackingService().track(
                        EVENTS.OPENED_TIMER_CHEST,
                    );
                } catch (e) {
                    console.log(e);
                }
            } else {
                for (let i = 0; i < countOfRewardsInChest; i++) {
                    const chestReward = getChestReward(user);

                    if (!chestReward) {
                        console.error('No chest reward');

                        return false;
                    }

                    const rewardId = defaultReward?.value || chestReward.id;

                    const claimedItemData = {
                        avatar: chestReward,
                        type: 'chest',
                    };

                    store.commit('setClaimedItem', {
                        index: i,
                        ...claimedItemData,
                    });

                    const combinedData = { itemType: 'avatar', ...chestReward };

                    try {
                        await Api().post(
                            `home-game-v10SimpleTreeSocial/battle-pass/unlock`,
                            [rewardId],
                        );

                        await Api().post(
                            `home-game-v10SimpleTreeSocial/battle-pass/claim/${rewardId}/`,
                            combinedData,
                        );

                        await Api().post(
                            'home-game-v10SimpleTreeSocial/open-chest-main',
                        );

                        await new TrackingService().track(
                            EVENTS.OPENED_TIMER_CHEST,
                        );

                        store.dispatch('claimAvatar', chestReward);

                        store.dispatch('createActivityLog', claimedItemData);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }

            store.commit('setClaimModalOpen', true);

            setTimeout(() => {
                store.commit('setCanCloseClaimModal', true);
            }, 900);

            await store.dispatch('v2/user/fetchStudentInfo', null, {
                root: true,
            });

            return store.state.claimedItem;
        },
        async unlockDailyReward(store) {
            try {
                await SecureApi().post('solo-track/rewards/daily/unlock');

                store.dispatch('v2/user/fetchStudentInfo', null, {
                    root: true,
                });
            } catch (e) {
                console.log(e);
            }
        },
        /**
         * @deprecated Evening Rewards removed from UI (12.08.2024)
         */
        async claimEveningReward(store, { reward }) {
            let rewardsToClaim = [reward];

            let itemToClaim = reward;

            try {
                if (reward.type === 'bundle') {
                    // refill reward
                    await store.dispatch('refillEnergy');

                    // pet reward
                    const petReward = {
                        name: 'DRAGON DOG',
                        type: 'monster',
                        id: 'bubbles',
                    };

                    // mBucks reward
                    const mBucksReward = {
                        name: 'MBUCKS',
                        type: 'mbucks',
                        value: '5 mBucks',
                    };

                    rewardsToClaim = [
                        mBucksReward,
                        petReward,
                        petReward,
                        petReward,
                    ];

                    itemToClaim = {
                        ...reward,
                        bundle: rewardsToClaim,
                    };
                }

                const response = await SecureApi().post(
                    'battlepass/rewards/unlock',
                    rewardsToClaim,
                );

                const { success } = response.data;

                if (success) {
                    await store.dispatch('claimDailyReward', {
                        reward,
                        timeKey: 'homeTime',
                    });

                    store.dispatch('claimBattlePassItem', {
                        item: itemToClaim,
                        level: reward.key,
                        isMathPass: false,
                    });
                } else {
                    console.warn('Unable to unlock reward', reward);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async claimDailyReward(store, { timeKey, reward }) {
            let claimedItem;

            switch (reward.type) {
                case 'avatar':
                case 'monster':
                    claimedItem = reward.id;
                    break;
                default:
                    claimedItem = reward.type;
            }

            try {
                const result = await Api().post(
                    `solo-track/rewards/daily/claim`,
                    { timeKey, rewards: [claimedItem] },
                );

                const { success, data } = result.data;

                if (success) {
                    store.dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );
                }
            } catch (e) {
                console.log(e);
            }
        },
        async claimCardGift(store, claimedCard) {
            store.commit('clearClaimedItems');

            try {
                const result = await Api().post(`/home-game/cards/add`, {
                    name: claimedCard.name,
                    amount: 1,
                });

                const { success, data } = result.data;

                if (success) {
                    store.dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );
                }

                await Api().post(
                    'home-game-v10SimpleTreeSocial/open-chest-main',
                );

                await new TrackingService().track(EVENTS.OPENED_TIMER_CHEST);

                const claimedItemData = {
                    ...claimedCard,
                    card: claimedCard,
                    type: 'card',
                };

                store.commit('setClaimedItem', claimedItemData);
                store.commit('setShowClaimedItem', true);
                store.dispatch('createActivityLog', claimedItemData);

                await store.dispatch('v2/user/setClaimable', null, {
                    root: true,
                });

                setTimeout(() => {
                    store.commit('setCanCloseClaimModal', true);
                }, 900);
            } catch (e) {
                console.log(e);
            }
        },
        async claimBattlePassItem(store, { item, level }) {
            let chestReward = null;
            let bundle = null;
            let itemData;

            store.commit('clearClaimedItems');

            const user = store.rootGetters.user;

            store.commit('setCanCloseClaimModal', false);
            console.log({ item });
            switch (item.type) {
                case 'body':
                case 'full-face':
                case 'headgear':
                case 'hair-front':
                case 'hair':
                case 'brows':
                case 'eyes':
                case 'eyewear':
                case 'mouth':
                case 'hairColor':
                case 'bodyColor':
                    const partId = item.value;
                    itemData = { avatarPart: item, id: partId };
                    chestReward = itemData;
                    break;
                case 'avatar':
                    itemData = {
                        type: 'avatar',
                        ...store.getters.getAllAvatars.find(
                            (avatar) => avatar.id === item.id,
                        ),
                    };
                    itemData = { avatar: itemData, type: 'avatar' };
                    store.commit('setChestReward', itemData);

                    break;
                case 'avatar-part':
                    chestReward = getAvatarItemsReward(user, item.rarity);
                    itemData = { avatarPart: chestReward };
                    store.commit('setChestReward', itemData);

                    break;
                case 'background':
                    itemData = {
                        type: 'background',
                        ...getBackgrounds.find(
                            (background) => background.id === item.id,
                        ),
                    };

                    break;
                case 'chest':
                    chestReward = getChestReward(user);
                    store.commit('setChestReward', chestReward);
                    itemData = { avatar: chestReward, type: 'chest' };

                    break;
                case 'card':
                    itemData = { card: item, type: 'card' };

                    break;
                case 'mystery-card':
                    chestReward = getCardReward(user, item.rarity === 'rare');
                    chestReward.value = chestReward.name;
                    chestReward.type = 'card';
                    itemData = {
                        card: chestReward,
                        type: item.type,
                        value: chestReward.value,
                    };
                    store.commit('setChestReward', chestReward);

                    break;
                case 'decoration':
                    chestReward = getDecorationReward(user);
                    store.commit('setChestReward', chestReward);
                    itemData = {
                        decoration: chestReward,
                        type: 'decoration',
                    };

                    break;
                case 'frame':
                    const frameId = item.value;
                    itemData = { frame: item, type: 'frame', id: frameId };
                    chestReward = itemData;
                    break;
                case 'bundle':
                    // todo: this switch should go to a function and be applied to every bundle element
                    bundle = item.bundle.map((subItem) => {
                        return {
                            type:
                                subItem.type === 'monster'
                                    ? 'card'
                                    : subItem.type,
                            id: subItem.id,
                            value: subItem.value,
                        };
                    });

                    break;
                default:
                    console.warn('Unknown item type claimed', item);
            }

            const claimedItemData = { ...item, ...itemData };

            store.commit('setClaimedItem', claimedItemData);
            store.commit('setShowClaimedItem', true);

            const rewardsToClaim =
                item.type === 'bundle'
                    ? bundle
                    : [
                          {
                              type: itemData?.type || item.type,
                              id: item.id,
                              value: itemData?.value || item.value,
                          },
                      ];
            if (chestReward) rewardsToClaim.push(chestReward);

            try {
                const result = await store.dispatch(
                    'v2/student/claimReward',
                    {
                        source: 'battle-pass',
                        sourceId: item.sourceId,
                        rewards: rewardsToClaim,
                    },
                    { root: true },
                );

                if (result) {
                    if (
                        item.type === 'avatar' ||
                        chestReward?.type === 'battle-pass-gacha'
                    ) {
                        store.dispatch(
                            'claimAvatar',
                            chestReward?.id ? chestReward.id : item,
                        );
                    }

                    if (!item.sourceId) {
                        await new TrackingService().track(
                            EVENTS.CLAIMED_REWARD,
                            {
                                level,
                                track: item.battlePass ? 'payed' : 'free',
                                mBucks:
                                    store.rootGetters.user?.studentInfo
                                        ?.battlepass?.gems || 0,
                            },
                        );

                        store.dispatch('createActivityLog', claimedItemData);
                    }

                    if (item.type === 'mbucks') {
                        await new TrackingService().track(
                            EVENTS.SET_MBUCKS,
                            store.rootGetters.user?.studentInfo?.battlepass
                                ?.gems || 0,
                        );
                    }
                } else {
                    return;
                }
            } catch (e) {
                console.log(e);
            }

            await store.dispatch('v2/user/setClaimable', null, { root: true });

            setTimeout(() => {
                store.commit('setCanCloseClaimModal', true);
            }, 900);
        },
        async buyItem(store, { type, item }) {
            try {
                item.type = type;

                const response = await Api().post(`battlepass/shop/buy-item`, {
                    id: item.id,
                    price: item.gemCost,
                });

                const { data, success } = response.data;

                if (success) {
                    let claimedItem = {};

                    claimedItem = item;

                    console.log({ claimedItem });

                    if (type === 'avatar') {
                        claimedItem = {
                            ...item,
                            avatar: { id: item.id, name: item.name },
                        };

                        store.dispatch('claimAvatar', claimedItem);
                    } else if (type === 'frame') {
                        claimedItem = {
                            ...item,
                            frame: { id: item.id, name: item.name },
                        };
                    } else if (type === 'background') {
                        claimedItem = {
                            ...item,
                            background: { id: item.id, name: item.name },
                        };
                    }
                    if (type !== 'decoration') {
                        store.dispatch('createActivityLog', claimedItem);
                    }

                    store.dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );

                    return true;
                }
            } catch (e) {
                console.log(e);
            }

            return false;
        },
        async buyEmotePack(store) {
            const item = {
                id: `EMOJI_PACK`,
                name: 'Emotes pack',
                type: 'emoji-pack',
                gemCost: 99,
            };

            await store.dispatch('buyItem', {
                type: item.type,
                item,
            });
        },
        async buyEnergy(store) {
            const item = {
                id: `ENERGY_REFILL`,
                name: 'Energy refill',
                type: 'energy-refill',
                gemCost: 5,
            };

            await store.dispatch('buyItem', {
                type: item.type,
                item,
            });
        },
        async handleCloseClaimModal({ commit, state }) {
            if (state.activeClaimedItemIndex < state.claimedItems.length) {
                commit('setShowClaimedItem', false);

                commit(
                    'setActiveClaimedItem',
                    state.activeClaimedItemIndex + 1,
                );

                setTimeout(() => commit('setShowClaimedItem', true), 0);
            } else {
                commit('setClaimModalOpen', false);

                commit('clearClaimedItems');

                commit('setActiveClaimedItem', 0);
            }
        },
        checkAndUnlockRewards({ rootGetters, dispatch }) {
            const rewards = rootGetters['v2/user/getDailyRewards'];

            const timeSlot = rewards.homeTime;

            if (
                timeSlot.unlocked === 0 ||
                timeSlot.unlocked === timeSlot.claimed
            ) {
                dispatch('unlockDailyReward');
            }
        },
        async consumeEmoji({ dispatch }, emojiName) {
            const response = await SecureApi().post(
                `home-game-v10SimpleTreeSocial/consume-emoji/${emojiName}`,
            );

            const { data, success, error } = response.data;

            if (success) {
                dispatch(
                    'v2/user/update',
                    { studentInfo: data },
                    { root: true },
                );
            } else if (error) {
                console.error(`[consumeEmoji] server error: ${error}`);
            }
        },
        speedUpBuilding({ dispatch, getters }) {
            const startTime = moment().utc();

            return SecureApi()
                .post(`buildings/speed-up`, {
                    id: getters.currentStudentIslandKey,
                })
                .then((response) => {
                    const { data, serverTime } = response.data;

                    if (data) {
                        return Promise.all([
                            dispatch('updateStudentBuildings', {
                                data: data?.buildingInfo,
                                startTime,
                                serverTime,
                            }),

                            dispatch('v2/user/update', data, { root: true }),
                        ]);
                    }
                })
                .catch((error) =>
                    console.error('[speedUpBuilding] API error: ', error),
                );
        },
        updateStudentBuildings(
            { commit, state, dispatch },
            { data, startTime, serverTime },
        ) {
            commit('setStudentBuildings', data?.areas);

            if (!state.studentIslandToDisplay) {
                const currentIsland = state.studentIslandsMeta.find(
                    (island) => island && island.key === data?.currentArea,
                );

                commit('setStudentIslandToDisplay', currentIsland?.number || 1);
            }

            dispatch('updateTimeCorrection', { startTime, serverTime });
        },
        loadStudentBuildings({ dispatch }) {
            const startTime = moment().utc();

            return SecureApi()
                .get(`buildings`)
                .then((response) => {
                    const { data, serverTime } = response.data;

                    return dispatch('updateStudentBuildings', {
                        data,
                        startTime,
                        serverTime,
                    });
                });
        },
        loadStudentBuildingsConfig({ state, commit }) {
            if (!state.studentBuildingsConfig['playground']) {
                return SecureApi()
                    .get(`buildings/config`)
                    .then((response) => {
                        commit(
                            'setStudentBuildingsConfig',
                            response.data?.data,
                        );
                    });
            }
        },
        startStudentBuilding({ commit, dispatch, getters }) {
            const startTime = moment().utc();

            return SecureApi()
                .post(`buildings/start`, {
                    id: getters.currentStudentIslandKey,
                })
                .then((response) => {
                    const { data, serverTime } = response.data;

                    if (data) {
                        return Promise.all([
                            dispatch('updateStudentBuildings', {
                                data: data?.buildingInfo,
                                startTime,
                                serverTime,
                            }),

                            dispatch('v2/user/update', data, { root: true }),
                        ]);
                    }
                })
                .catch((error) =>
                    console.error('[startBuilding] API error: ', error),
                );
        },
        speedUpStudentBuilding({ commit, dispatch, getters }) {
            const startTime = moment().utc();

            return SecureApi()
                .post(`buildings/speed-up`, {
                    id: getters.currentStudentIslandKey,
                })
                .then((response) => {
                    const { data, serverTime } = response.data;

                    if (data) {
                        return Promise.all([
                            dispatch('updateStudentBuildings', {
                                data: data?.buildingInfo,
                                startTime,
                                serverTime,
                            }),

                            dispatch('v2/user/update', data, { root: true }),
                        ]);
                    }
                })
                .catch((error) =>
                    console.error('[speedUpBuilding] API error: ', error),
                );
        },
        openStudentBuildingStage({ dispatch, getters }) {
            const startTime = moment().utc();

            return SecureApi()
                .post(`buildings/open`, { id: getters.currentStudentIslandKey })
                .then((response) => {
                    const { data, serverTime } = response.data;

                    if (data) {
                        return dispatch('updateStudentBuildings', {
                            data,
                            startTime,
                            serverTime,
                        });
                    }
                })
                .catch((error) =>
                    console.error('[openBuildingStage] API error: ', error),
                );
        },
        fetchWeeklyLeaderboard({ commit, getters, rootGetters }) {
            const classCode = rootGetters['v2/user/getClassCode'];
            const islandKey = getters.currentIslandKey;

            return SecureApi()
                .get(`buildings/leaderboard/weekly/${classCode}/${islandKey}`)
                .then((response) => {
                    const { data } = response.data;
                    commit('setWeeklyLeaderboard', { classCode, data });
                    return data;
                })
                .catch((error) =>
                    console.error('[leaderboards] API error: ', error),
                );
        },
        fetchAllTimeLeaderboard({ commit, getters, rootGetters }) {
            const classCode = rootGetters['v2/user/getClassCode'];
            const islandKey = getters.currentIslandKey;

            return SecureApi()
                .get(`buildings/leaderboard/all-time/${classCode}/${islandKey}`)
                .then((response) => {
                    const { data } = response.data;
                    commit('setAllTimeLeaderboard', { classCode, data });
                    return data;
                })
                .catch((error) =>
                    console.error('[leaderboards] API error: ', error),
                );
        },
        setStudentTopic({ commit }, topic) {
            console.debug('store::homegame setStudentTopic', topic);
            commit('chooseTopic', topic);
            localStorage.setItem('student-current-topic', topic);
        },
        updateTimeCorrection({ commit, state }, { startTime, serverTime }) {
            const currTime = moment().utc();
            const responseDiff = moment(currTime).diff(startTime);
            const serverDiffTime = moment(serverTime).diff(currTime);

            console.debug('diffTime: ', responseDiff, serverDiffTime);

            if (
                Math.abs(serverDiffTime + responseDiff) >
                moment.duration(5, 'seconds').asMilliseconds()
            ) {
                commit('setTimeCorrection', serverDiffTime + responseDiff);
            }

            console.debug('timeCorrection: ', state.timeCorrection);
        },
        addSSEvent: (store, data) => {
            setTimeout(() => store.commit('setSSEvent', data), 0);
        },
        updateIslandToDisplay: (
            { commit, state, getters, rootGetters },
            classCode,
        ) => {
            const { index, stage } = getters.userIsland(classCode);
            console.debug(
                'store::homegame::updateIslandToDisplay:',
                classCode,
                { index, stage },
            );

            commit('setIslandToDisplay', { classCode, index });
        },
        updateClassBuildings: (
            { commit, state, dispatch, getters },
            { data, startTime, serverTime },
        ) => {
            commit('setClassBuildings', data);

            if (startTime && serverTime)
                dispatch('updateTimeCorrection', { startTime, serverTime });
        },
        loadClassBuildings: async ({ dispatch, rootGetters }) => {
            const classCode = rootGetters['v2/user/getClassCode'];

            const startTime = moment().utc();

            try {
                const response = await Api().get(
                    `buildings/class/${classCode}`,
                );

                const { success, data, serverTime } = response.data;

                if (success) {
                    await dispatch('updateClassBuildings', {
                        data: data || {},
                        startTime,
                        serverTime,
                    });
                    await dispatch('updateIslandToDisplay', classCode);
                }
            } catch (err) {
                console.error('[loadClassBuildings] API error: ', err);
            }
        },
        loadClassBuildingsConfig: async ({ commit, rootGetters }) => {
            const classCode = rootGetters['v2/user/getClassCode'];

            try {
                const response = await Api().get(
                    `buildings/class/config/${classCode}`,
                );

                if (response.data?.data)
                    commit('setClassBuildingsConfig', response.data?.data);
            } catch (err) {
                console.error('[loadClassBuildingsConfig] API error: ', err);
            }
        },
        startClassBuilding: async ({ dispatch, getters, rootGetters }) => {
            const startTime = moment().utc();
            const classCode = rootGetters['v2/user/getClassCode'];

            try {
                const response = await Api().post(`buildings/class/start`, {
                    id: getters.currentIslandKey,
                    classCode,
                });
                const { data, serverTime } = response.data;

                if (data) {
                    await Promise.all([
                        dispatch('updateClassBuildings', {
                            data: data?.buildingInfo,
                            startTime,
                            serverTime,
                        }),

                        dispatch('v2/user/update', data, { root: true }),
                    ]);
                }
            } catch (err) {
                console.error('[startBuilding] API error: ', err);
            }
        },
        openClassBuildingStage: async ({ dispatch, getters, rootGetters }) => {
            const classCode = rootGetters['v2/user/getClassCode'];
            const startTime = moment().utc();

            try {
                const response = await Api().post(`buildings/class/open`, {
                    id: getters.currentIslandKey,
                    classCode,
                });

                const { data, serverTime } = response.data;

                if (data)
                    await Promise.all([
                        dispatch('updateClassBuildings', {
                            data: data?.buildingInfo,
                            startTime,
                            serverTime,
                        }),

                        dispatch('v2/user/update', data, { root: true }),
                    ]);
            } catch (err) {
                console.error('[openBuildingStage] API error: ', err);
            }
        },
        commitStars: async (store) => {
            const classCode = store.rootGetters['v2/user/getClassCode'];

            try {
                const response = await Api().post(
                    `buildings/class/commit-stars`,
                    {
                        id: store.getters.currentIslandKey,
                        classCode,
                    },
                );
                const { data, serverTime } = response.data;
                const startTime = moment().utc();

                if (data?.studentInfo) {
                    store.dispatch('v2/user/update', data, {
                        root: true,
                    });
                }
                if (data?.buildingInfo) {
                    return store.dispatch('updateClassBuildings', {
                        data: data.buildingInfo,
                        startTime,
                        serverTime,
                    });
                }
            } catch (err) {
                console.error('[openBuildingStage] API error: ', err);
            }
        },
        commitStarsEventHandler: (store, event) => {
            if (store.rootGetters['v2/user/getClassCode'] === event.receiverId)
                setTimeout(
                    () => {
                        store.commit('setStarsPopup', {
                            userInfo: event.userInfo,
                            stars: event.metadata?.starsCommited,
                        });
                    },
                    store.state.starsPopup ? 1500 : 0,
                );

            store.dispatch('updateClassBuildings', {
                data: event.update?.buildingInfo,
            });
            store.commit('setWeeklyLeaderboard', {
                classCode: event.update?.buildingInfo?._id,
                data: event.update?.weeklyLeaderboard,
            });
        },
        syncBattlePassV21: async (store) => {
            const battlePassV21Synced =
                store.rootGetters.user?.flags?.battlePassV21Synced;
            if (battlePassV21Synced) return;
            let tasksSolvedToAdd = 0;
            const myCurrentLevel = store.getters.getMyCurrentLevel;
            const myCurrentLevelOldConfig =
                store.getters.getMyCurrentLevelFromOldConfig;
            if (myCurrentLevel < myCurrentLevelOldConfig) {
                tasksSolvedToAdd =
                    store.getters.getXpForOldLevelFromNewConfig -
                    store.getters._getXp;
            }
            if (tasksSolvedToAdd) {
                await store.dispatch('addTotalTasksSolved', tasksSolvedToAdd, {
                    root: true,
                });
            }
            await store.dispatch('flipFlag', FLAGS.BATTLE_PASS_V_21_SYNCED, {
                root: true,
            });
        },
        loadBuildingWeeklyReward: async (store) => {
            try {
                const response = await Api().get(
                    '/notifications/my?type=buildingWeeklyReward',
                );

                const { success, data } = response.data;

                if (success) {
                    store.commit('setBuildingWeeklyReward', data);
                }
            } catch (e) {
                console.error(e);
            }
        },
        resetCurrentTopic({ getters, dispatch }) {
            let currentTopic = localStorage.getItem('student-current-topic');
            if (!currentTopic || currentTopic === 'undefined') {
                currentTopic =
                    getters.currentGradeSkillList()?.[0]?.category || '';
            }
            dispatch('setStudentTopic', currentTopic);
        },
        setBrainBreakPass(store, payload) {
            store.commit('setBrainBreakPass', payload);
        },
    },
};

export default store;
