/* global klaviyo */

import store from '@/store';
import { getUserFromJwtToken } from '@/flows/Authentication/helpers/AuthHelpers';

export const KLAVIYO_EVENTS = {
    VISITED_HOME_PRACTICE: 'Visited Home Practice',
    CLICKED_PRINT_LOGINS: 'Clicked "Print logins"',
    CLICKED_PRINT_PARENT_LETTER: 'Clicked "Print Printouts"',
    CLICKED_SHARE_VIA_EMAIL_PARENT_LETTER: 'Clicked "Copy message for email"',
    VISITED_REPORTS: 'Visited Reports',
    FIRST_STUDENT_PLAYED_AT_HOME: 'First Student played at home', //
    HALF_CLASS_PLAYED_AT_HOME: '50% of the class played at home', //
    ENTIRE_CLASS_PLAYED_AT_HOME: 'Entire class played at home', //
    MONTH_BEFORE_SUBSCRIPTION_RENEWAL: '1 month before subscription renewal', //
    WEEK_BEFORE_SUBSCRIPTION_RENEWAL: '7 days before subscription renewal', //
    TWO_WEEKS_INACTIVITY: "Didn't use for 2 weeks (exept for breakes)", //
    MONTHLY_COMPETITION_VIEWS_COMPETITION_LANDING_PAGE:
        'Views competition landing page',
    MONTHLY_COMPETITION_VIEWS_COMPETITION_REGISTER_PAGE:
        'Views competition register page',
    openToPaidResources: 'Open to paid resources',
};

const eventExistsInEventList = (event) => {
    for (let eventKey in KLAVIYO_EVENTS) {
        if (KLAVIYO_EVENTS[eventKey] && KLAVIYO_EVENTS[eventKey] === event) {
            return true;
        }
    }

    return false;
};

export const KlaviyoService = (function () {
    /** Constructor */
    function KlaviyoService(user = null) {
        if (!user) {
            user = store.getters.user;

            if (!user) {
                user = getUserFromJwtToken(
                    localStorage.getItem('userDataToken'),
                );
            }
        }

        if (!user) {
            console.error('KS: unable to get user');
            return;
        }

        if (user.isGuest) {
            console.error('KS: user is guest');
            return;
        }

        if (!user.email && !user.username) {
            console.error('KS: corrupted user');
            return;
        }

        this.user = user;

        this.klaviyoId = user.email ? user.email : user.username;
    }

    KlaviyoService.prototype.track = function (event = '', args = {}) {
        if (!this.user || !this.klaviyoId) {
            console.error(
                `KS: Trying to send event '${event}' with anonymous user`,
            );

            return;
        }
        if (!eventExistsInEventList(event)) {
            console.error(`KS: event '${event}' is not listed in EVENTS`);
            return;
        }

        privateMethods.initKlaviyo.call(this, this.klaviyoId);
        console.log(`KLAVIYO: sending ${event}`);
        window.klaviyo && window.klaviyo?.push(['track', event, { ...args }]);
    };

    KlaviyoService.prototype.setUserProperties = function (props = {}) {
        if (!this.user || !this.klaviyoId) {
            console.error(`KS: Trying to set user props with anonymous user`);

            return;
        }

        privateMethods.setUserPropertiesKlaviyo.call(
            this,
            this.klaviyoId,
            props,
        );
    };

    const privateMethods = {
        initKlaviyo(klaviyoId) {
            window.klaviyo &&
                window.klaviyo?.push([
                    'identify',
                    {
                        email: klaviyoId,
                    },
                ]);
        },
        setUserPropertiesKlaviyo(klaviyoId, props) {
            console.debug('Klaviyo Identify user prop Payload:', {
                email: klaviyoId,
                ...props,
            });
            window.klaviyo &&
                window.klaviyo?.push([
                    'identify',
                    {
                        email: klaviyoId,
                        ...props,
                    },
                ]);
        },
    };

    return KlaviyoService;
})();
