/* global mixpanel */

export const STUDENT_PARK_EVENTS = {
    STUDENT_PARK_VIEWED_TAB_PETS: 'studentParkViewedTabPets',
    STUDENT_PARK_VIEWED_TAB_DECORATIONS: 'studentParkViewedTabDecorations',
    STUDENT_PARK_VIEWED_TAB_TREASURE: 'studentParkViewedTabTreasure',
    STUDENT_DIGGED_IN_A_PARK: 'studentDiggedInAPark',
    STUDENT_ADD_FAVORITE_PET: 'studentAddFavoritePet',
    STUDENT_REMOVE_FAVORITE_PET: 'studentRemoveFavoritePet',
    STUDENT_LEVELED_UP_PET: 'studentLeveledUpPet',
    STUDENT_SHOWS_PET: 'studentShowsPet',
    STUDENT_HIDES_PET: 'studentHidesPet',
};

export const STUDENT_PARK_METHODS = {
    studentParkViewedTabPets({ petsCount }) {
        // mixpanel.track('Viewed Park tab Pets', {
        //     'Number of pets': petsCount,
        // });
    },
    studentParkViewedTabDecorations({ decorationsCount }) {
        // mixpanel.track('Viewed Park tab Decorations', {
        //     'Number of decorations': decorationsCount,
        // });
    },
    studentParkViewedTabTreasure({ availableDigsCount }) {
        // mixpanel.track('Viewed Park tab Treasure', {
        //     'Available digs': availableDigsCount,
        // });
    },
    studentDiggedInAPark({ rewardType, userId }) {
        // mixpanel.track('Digged in a park', {
        //     'Reward type': rewardType,
        //     'Whose park': userId,
        // });
    },
    studentAddFavoritePet({ pet, category }) {
        // mixpanel.track('Added favorite pet', {
        //     'Pet name': pet,
        //     'Pet category': category,
        // });
    },
    studentRemoveFavoritePet({ pet, category }) {
        // mixpanel.track('Removed favorite pet', {
        //     'Pet name': pet,
        //     'Pet category': category,
        // });
    },
    studentLeveledUpPet({ pet, level }) {
        // mixpanel.track('Student leveled up pet', {
        //     'Pet name': pet,
        //     'Pet level': level,
        // });
    },
    studentShowsPet({ pet, level }) {
        // mixpanel.track('Student shows pet', {
        //     'Pet name': pet,
        //     'Pet level': level,
        //     Action: 'show',
        // });
    },
    studentHidesPet({ pet, level }) {
        // mixpanel.track('Student hides pet', {
        //     'Pet name': pet,
        //     'Pet level': level,
        //     Action: 'hide',
        // });
    },
};
