import { FLAGS } from '@/core/flags.js';
import CONSTANTS from '@/core/helpers/constants';
import {
    getPrivacyPolicyLink,
    getTermsOfUseLink,
    inCapacitorBuildEnv,
    inProductionEnv,
    mobilePaymentsEnabled,
} from '@/core/helpers/utils';

export default {
    computed: {
        $v2() {
            return this.$store.state.v2.game.mode === CONSTANTS.LIVE_GAME_MODE;
        },
        $user() {
            return this.$store.getters.user;
        },
        $userId() {
            return this.$store.getters['v2/user/uid'];
        },
        $playerName() {
            return this.$store.getters['v2/user/playerName'];
        },
        $isGuest() {
            return !this.$store.getters['v2/user/uid'];
        },
        $hasSchoolPremiumOrTrial() {
            if (this.$user?.role === 'student') {
                return (
                    this.$user?.premium?.status === 'active' ||
                    this.$user?.premium?.status === 'trial'
                );
            }

            return (
                this.$user?.premium?.status === 'active' ||
                this.$store.getters['v2/teacherPremium/hasSchoolPremiumTrial']
            );
        },
        $showDiscoverPremium() {
            return (
                localStorage.getItem('country_code3')?.includes('USA') ||
                this.$user?.country?.includes('USA')
            );
        },
        $hasCityHub() {
            return false;
            // const cityHubCountries = ['USA - North Carolina', 'Estonia'];
            //
            // const cityHubGroups = ['group-2', 'group-3', 'group-6'];
            //
            // return (
            //     cityHubGroups.includes(this.$user?.testGroup) ||
            //     cityHubCountries.includes(this.$user?.country)
            // );
        },
        $isStudent() {
            return this.$store.getters['v2/user/isStudent'];
        },
        $isTeacher() {
            return this.$store.getters['v2/user/isTeacher'];
        },
        $isParent() {
            return this.$store.getters['v2/user/isParent'];
        },
        $isFromUS() {
            return this.$store.getters['v2/user/isFromUS'];
        },
        $isUSTeacher() {
            return this.$store.getters['v2/user/isUSTeacher'];
        },
        $CONSTANTS() {
            return CONSTANTS;
        },
        $currentLanguage() {
            return this.$store.getters['getCurrentLanguage'];
        },
        $inProduction() {
            return inProductionEnv();
        },
        $paymentsEnabled() {
            return !inCapacitorBuildEnv() || mobilePaymentsEnabled();
        },
        $getTermsOfUseLink() {
            return getTermsOfUseLink(this.currentLanguage);
        },
        $getPrivacyPolicyLink() {
            return getPrivacyPolicyLink(this.currentLanguage);
        },
        $showTestTool() {
            return (
                localStorage?.getItem('dev-tools') === 'true' ||
                this.$user?.debug
            );
        },
    },
};
