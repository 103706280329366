/* global mixpanel */

export const CARD_COLLECTION_EVENTS = {
    STUDENT_ASKED_CARD: 'studentAskedCard',
    STUDENT_SENT_CARD: 'studentSentCard',
    STUDENT_SENT_THANK: 'studentSentThank',
    STUDENT_GIFT_CARD: 'studentGiftCard',
    STUDENT_CLAIMED_GIFTED_CARD: 'studentClaimedGiftedCard',
};

export const CARD_COLLECTION_METHODS = {
    studentAskedCard() {
        // mixpanel.track('Student asked card');
    },
    studentSentCard() {
        mixpanel.track('Student sent card');
    },
    studentSentThank() {
        mixpanel.track('Student sent a thank you');
    },
    studentClaimedGiftedCard() {
        mixpanel.track('Student claimed gifted card');
    },
    studentGiftCard(name) {
        mixpanel.track('Student gifted card', {
            Card: name,
        });
    },
};
